import React, { useEffect, useState } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
interface IViewAllConfigProps {
  text: string
  onViewAllClick: () => void
}
interface IPaginationProps {
  onPageNumberClick?: (clickedPageNumber: number | null) => void
  currentPageNumber: number
  totalRecords: number
  pageSize: number
  viewAllConfig?: IViewAllConfigProps
  border?: boolean
}
interface IPagingObject {
  pageNum: number
  isDotItem: boolean
}
function getTotalPages(totalRecords: number, pageSize: number): number {
  const pageSizeLocal = pageSize > 0 ? pageSize : 1
  return Math.ceil(totalRecords / pageSizeLocal)
}
export default function Pagination(props: IPaginationProps): JSX.Element {
  const {
    currentPageNumber,
    onPageNumberClick,
    totalRecords,
    pageSize,
    border = false,
  } = props

  const [totalPages, setTotalPages] = useState(
    getTotalPages(totalRecords, pageSize),
  )
  const [currentPageNum, setCurrentPageNum] =
    useState<number>(currentPageNumber)
  const [numberList, setNumberList] = useState<IPagingObject[]>([])

  const maxNumberToDisplay = 5
  const maxNumberBeforeEndDot = maxNumberToDisplay - 2
  useEffect(() => {
    renderPageNum(currentPageNumber, totalPages)
    setCurrentPageNum(currentPageNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber])

  useEffect(() => {
    const totalPagesLocal = getTotalPages(totalRecords, pageSize)
    setTotalPages(totalPagesLocal)
    renderPageNum(currentPageNum, totalPagesLocal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecords, pageSize])

  const renderPageNum = (
    selectedPageNumber: number,
    totalPageNumber: number,
  ): void => {
    if (
      selectedPageNumber === null ||
      isNaN(selectedPageNumber) ||
      selectedPageNumber < maxNumberBeforeEndDot ||
      totalPageNumber <= maxNumberToDisplay
    ) {
      renderDefault(totalPageNumber)
    } else if (
      selectedPageNumber + 1 <= totalPageNumber ||
      selectedPageNumber === totalPageNumber
    ) {
      renderList(selectedPageNumber, totalPageNumber)
    }
  }

  const renderItems = (
    selectedPageNumber: number,
    totalPageNumber: number,
  ): IPagingObject[] => {
    const itemList = []
    if (selectedPageNumber + 3 >= totalPageNumber) {
      for (let i = totalPageNumber - 4; i < totalPageNumber; i++) {
        itemList.push(getPagingObject(i))
      }
      return itemList
    }
    itemList.push(getPagingObject(selectedPageNumber - 1))
    itemList.push(getPagingObject(selectedPageNumber))
    itemList.push(getPagingObject(selectedPageNumber + 1))
    itemList.push(getPagingObject(0, true))
    return itemList
  }

  const renderList = (
    selectedPageNumber: number,
    totalPageNumber: number,
  ): void => {
    const itemList: IPagingObject[] = []
    itemList.push(getPagingObject(1))
    itemList.push(getPagingObject(0, true))
    itemList.push(...renderItems(selectedPageNumber, totalPageNumber))
    itemList.push(getPagingObject(totalPageNumber))
    setNumberList(itemList)
  }

  const renderDefault = (totalPageNumber: number): void => {
    const itemList = []
    for (let i = 1; i <= totalPageNumber; i++) {
      if (i <= maxNumberBeforeEndDot) {
        itemList.push(getPagingObject(i))
      }
      if (totalPageNumber > maxNumberToDisplay && i > maxNumberBeforeEndDot) {
        itemList.push(getPagingObject(0, true))
        itemList.push(getPagingObject(totalPageNumber))
        break
      }
      if (totalPageNumber <= maxNumberToDisplay && i > maxNumberBeforeEndDot) {
        itemList.push(getPagingObject(i))
      }
    }
    setNumberList(itemList)
  }

  const getPagingObject = (
    pageNum: number,
    isDotItem = false,
  ): IPagingObject => {
    return { pageNum, isDotItem }
  }

  const renderNumbers = (): JSX.Element[] => {
    return numberList.map((item: IPagingObject, index: number) => {
      if (item.isDotItem) {
        return renderDotItem()
      }
      return renderNumberItem(item.pageNum, index)
    })
  }

  const renderNumberItem = (pageNum: number, key: number): JSX.Element => {
    return (
      <li
        className={
          currentPageNum === pageNum
            ? 'hire-assisto-pagination__list hire-assisto-pagination__list--active mx-1'
            : 'hire-assisto-pagination__list mx-1'
        }
        onClick={(event) => {
          onClick(event, pageNum)
        }}
        key={key}>
        <button className="hire-assisto-pagination__list-item">
          {pageNum}
        </button>
      </li>
    )
  }

  const renderDotItem = (): JSX.Element => {
    return (
      <li className="hire-assisto-pagination__list">
        <span className="hire-assisto-pagination__list-item">{'...'}</span>
      </li>
    )
  }

  const onClick = (
    event: React.MouseEvent<HTMLElement> | null,
    pageNumber: number,
  ): void => {
    renderPageNum(pageNumber, totalPages)
    setCurrentPageNum(pageNumber)
    ;(event?.target as HTMLElement)?.blur()
    onPageNumberClick?.(pageNumber)
  }

  return (
    <>
      <div
        className={
          border
            ? 'hire-assisto-pagination-container hire-assisto-pagination-container--border'
            : 'hire-assisto-pagination-container'
        }>
        <div className="d-flex justify-content-end">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-3 col-12">
            <nav aria-label="navigation">
              <ul className="hire-assisto-pagination">
                <li className="hire-assisto-pagination__list">
                  <button
                    className="hire-assisto-pagination__list-item mx-3"
                    aria-label="Previous"
                    disabled={
                      (currentPageNum !== null && currentPageNum <= 1) ||
                      totalPages <= 0
                    }
                    onClick={(event) => {
                      onClick(
                        event,
                        currentPageNum !== null ? currentPageNum - 1 : 0,
                      )
                    }}>
                    <span aria-hidden="true">
                      <BiChevronLeft />
                    </span>
                  </button>
                </li>
                {renderNumbers()}
                <li className="hire-assisto-pagination__list">
                  <button
                    className="hire-assisto-pagination__list-item mx-3"
                    aria-label="Next"
                    disabled={
                      (currentPageNum ?? 0) >= (totalPages ?? 0) ||
                      (totalPages ?? 0) <= 0 ||
                      currentPageNum === null
                    }
                    onClick={(event) => {
                      onClick(
                        event,
                        currentPageNum !== null ? currentPageNum + 1 : 0,
                      )
                    }}>
                    <span aria-hidden="true">
                      <BiChevronRight />
                    </span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
