import { Button, Modal } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

//props
interface IConfirmationModalProps {
  id?: number;
  showPopup: boolean;
  handleButtonClick: (e: React.MouseEvent<HTMLElement>) => any;
  setShowPopup: (close: boolean) => void;
  modelDescription: string;
  buttonLabel: string;
}

export default function ConfirmationModal(props: IConfirmationModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      data-testid="delete-job-modal"
      show={props.showPopup}
      onHide={() => props.setShowPopup(false)}
    >
      <div className="px-5 py-5">
        <h4 className="text-center">{props.modelDescription}</h4>
        <div className="container-fluid mt-5 d-flex justify-content-around">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
            <Button
              onClick={props.handleButtonClick}
              data-testid="delete-job-yes"
              className="bg-dark shadow-1-strong border-dark save-btn__onSave  px-4 mt-2"
            >
              {props.buttonLabel}

            </Button>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
            <Button
              data-testid="delete-job-discard"
              onClick={(e) => props.setShowPopup(false)}
              className="bg-light shadow-1-strong text-dark border-dark mx-3 mt-2 save-btn__onSave discard-btn"
            >
              {t("JOBS_PAGE.DISCARD_BTN")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
