import React, { useState, useEffect } from "react";
import Select from "react-select";
import { validEmailPattern } from "../../utils/regexExpressions";
import Toaster, { IToasterProps } from "../Toaster";
import { filterValidEmails } from "../../utils/emailValidation";
export interface EmailInputValue {
  label: string;
  value: string;
  isSelected: boolean;
}
interface emailVal {
  emails?: EmailInputValue[] | undefined;
  handleUpdateEmailsData?: (updatedEmails: EmailInputValue[]) => void;
}

export default function CustomMultiSelect(props: emailVal): JSX.Element {
 
  const [emails, setEmails] = useState<EmailInputValue[]>([]);
  const [newEmail, setNewEmail] = useState<string>("");
  const [tempEmail, setTempEmail] = useState<string>("");
  const [tempData, setTempData] = useState<string>("");
  const [toasters, setToasters] = useState<IToasterProps[]>([]);

  useEffect(() => {
    if (props.emails) {
      let emailValue = filterValidEmails(props.emails!);
      setEmails(emailValue);
    }
  }, [props.emails]);

  useEffect(() => {
    if (newEmail) {
      let tempObj = {
        value: tempEmail,
        label: tempEmail,
        isSelected: true,
      };

      let emailAlreadyExist: EmailInputValue[];
      if (emails !== undefined) {
        emailAlreadyExist = emails?.filter(
          (emailsData: EmailInputValue) => emailsData.value !== tempEmail
        );
      } else {
        emailAlreadyExist = [];
      }

      emailAlreadyExist?.push(tempObj);
      const options = emailAlreadyExist?.map((emailData) => ({
        value: emailData.value,
        label: emailData.value,
        isSelected: true,
      }));
      setEmails(options);
    }
  }, [newEmail]);

  const addToast = (toast: IToasterProps) => {
    setToasters((prevToasters) => [...prevToasters, toast]);
  };

  const handleEnterPress = (e: any) => {

    if (
      e.target.value.length > 0 &&
      validEmailPattern.test(e.target.value) &&
      e.keyCode === 13
    ) {
      const options = {
        value: e.target.value,
        label: e.target.value,
        isSelected: true,
      };

      setEmails([...emails, options]);
      setTempEmail(e.target.value);

      setTempData("");
    }
  };
  const handleOnChange = (value: any, action: any) => {
    if (action.action === "input-change") {
      setTempData(value);
    }
  };
  const handleOnBlur = (e: any) => {
    if (tempData) {
      if (validEmailPattern.test(e.target.value)) {
        const options = {
          value: tempData,
          label: tempData,
          isSelected: true,
        };

        setEmails([...emails!, options]);
        let emailFilterValues = filterValidEmails([...emails!, options]);

        props.handleUpdateEmailsData &&
          props.handleUpdateEmailsData(emailFilterValues as any);

        setTempData("");
      } else {
        addToast({
          id: 1,
          message: "Enter valid email ID",
          variant: "danger",
        });
      }
    } else {
      props.handleUpdateEmailsData &&
        props.handleUpdateEmailsData(emails as any);
    }
  };

  const handleOnChangeOption = (remainingEmails: any) => {
    let emailFilterValues = filterValidEmails(remainingEmails);
    if (emails !== undefined) {
      setEmails(emailFilterValues);
      props?.handleUpdateEmailsData?.(emailFilterValues);
      setTempEmail("");
    }
  };

  return (
    <div>
      {toasters.map((toast) => (
        <Toaster key={toast.id} {...toast} />
      ))}
      <div className="scroll-height">
        <Select
          className="inputField"
          isMulti
          options={emails}
          defaultValue={emails}
          value={emails}
          onKeyDown={handleEnterPress}
          onChange={handleOnChangeOption}
          onInputChange={handleOnChange}
          inputValue={tempData}
          onBlur={handleOnBlur}
          noOptionsMessage={() => null}
        />
      </div>
    </div>
  );
}
