import { useState, useEffect } from "react";
import {
  getJobsPage,
  deleteJobDescription,
  downloadJobDescription,
  type IJDList,
} from "../../services/jobsAPIService";
import { AiOutlinePlus } from "react-icons/ai";
import ButtonWithIcon from "../../widgets/ButtonWithIcon";
import JobsTable from "./jobsTable";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Toaster, { type IToasterProps } from "../../widgets/Toaster";
import { Button } from "react-bootstrap";
import {
  createNewJobPath,
  employeeDetailsPath,
  findDuplicatePath,
} from "../../utils/routes";
import Pagination from "../../widgets/Pagination";

export default function Jobs(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [jobDescription, setJobDescription] = useState<IJDList[]>();
  const [toaster, setToaster] = useState<IToasterProps>({
    showToast: false,
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const location = useLocation();
  let intervalId: NodeJS.Timer;

  useEffect(() => {
    localStorage.setItem("currentPageNumber", "1");
    getJobDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber]);

  useEffect(() => {
    let intervalCount = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalId = setInterval(() => {
      getJobDetails();
      intervalCount++;
      if (intervalCount === 2) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getJobDetails = (): void => {
    getJobsPage({
      page: currentPageNumber,
      pageSize: 10,
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((resp: any) => {
        setJobDescription(resp.jd_list);
        setTotalRecords(resp.total_results);
      })
      .catch((err: Error) => {
        setToaster({
          ...toaster,
          message: err.message,
          variant: "danger",
          showToast: true,
        });
      });
  };

  const handleButtonClick = (id: number): void => {
    deleteJobDescription(id)
      .then(() => {
        getJobDetails();
      })
      .catch((err: Error) => {
        setToaster({
          ...toaster,
          message: err.message,
          variant: "danger",
          showToast: true,
        });
      });
  };
  const downloadJD = (id: number): void => {
    downloadJobDescription(id)
      .then((resp: BlobPart) => {
        const file = new Blob([resp], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow: Window | null = window.open();
        if (pdfWindow != null) {
          pdfWindow.location.href = fileURL;
        }
      })
      .catch((err: Error) => {
        setToaster({
          ...toaster,
          message: err.message,
          variant: "danger",
          showToast: true,
        });
      });
  };

  const onPageNumberClick = (pageNumber: number | null): void => {
    setCurrentPageNumber(pageNumber!);
  };
  return (
    <>
      {(toaster.showToast ?? false) && (
        <Toaster
          showToast={toaster.showToast}
          message={toaster.message}
          variant={toaster.variant}
        />
      )}
      <div className="container jobs-page-container">
        <div className="d-flex justify-content-end mt-4 p-0">
          <div className="mx-1">
            <Button
              variant="dark"
              onClick={() => {
                navigate(findDuplicatePath);
              }}
              data-testid="find-duplicate-button"
              className="jobs-page-container__create-new-btn d-flex justify-content-evenly"
            >
              {t("JOBS_PAGE.FIND_DUPLICATE")}
            </Button>
          </div>
          <div className="mx-3">
            <Button
              variant="dark"
              data-testid="employee-details-button"
              onClick={() => {
                navigate(employeeDetailsPath, {
                  state: { clearSelectedEmail: true },
                });
              }}
              className="jobs-page-container__create-new-btn d-flex justify-content-evenly"
            >
              {t("JOBS_PAGE.EMPLOYEE_DETAILS")}
            </Button>
          </div>
          <ButtonWithIcon
            label={t("JOBS_PAGE.NEW_JOB")}
            icon={<AiOutlinePlus className="my-1" />}
            testid="add-new-job"
            variant="dark"
            onClick={() => {
              navigate(createNewJobPath);
            }}
            className="jobs-page-container__create-new-btn d-flex justify-content-evenly"
          />
        </div>
        <div className="row justify-content-center mt-4">
          <div
            className="col-xl-12 col-lg-10 col-md-11 col-12 p-2 m-2"
            data-testid="jobs-table-container"
          >
            {jobDescription != null && (
              <JobsTable
                jobs={jobDescription}
                onDelete={(id: number) => {
                  handleButtonClick(id);
                }}
                onDownload={(id: number) => {
                  downloadJD(id);
                }}
                jobId={location.state?.job_id || null}
                currentPage={currentPageNumber}
                rowsPerPage={10}
              />
            )}
          </div>
          <div className="row justify-content-end align-items-center  pt-2 pb-5 ">
            <div className="col-xl-7 col-lg-6 col-md-10 col-sm-10 col-12 ">
              <Pagination
                currentPageNumber={currentPageNumber}
                totalRecords={totalRecords}
                pageSize={10}
                border={true}
                onPageNumberClick={onPageNumberClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
