import { EmailInputValue } from "../widgets/CustomMultiSelect";
import { validEmailPattern } from "./regexExpressions";

export const filterValidEmails = (emailList: EmailInputValue[]) => {
  const uniqueEmails: Set<string> = new Set();

  emailList.forEach((item: EmailInputValue) => {
    uniqueEmails.add(item.value);
  });
  const uniqueEmailsArray: string[] = Array.from(uniqueEmails);

  const emailInputValues: EmailInputValue[] = uniqueEmailsArray
    .filter((email) => validEmailPattern.test(email))
    .map((email: string) => ({
      label: email,
      value: email,
      isSelected: true,
    }));

  return emailInputValues;
};
