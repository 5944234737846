import { Delete, Get, Post, Put } from "../utils/apiServices";
import { type IJdDetails, type ICandidates } from "../pages/Jobs/CheckMatch";
import { type IJobDetails } from "../pages/Jobs/CreateNewJob";
import { type IResourceData } from "../pages/Jobs/ViewResource";

// const baseUrl = "http://49.206.245.146:8009";
// const emailNotificationBaseUrl = "http://notifyqa.nsglobalgroup.com:8084/";
// const notificationServiceToken = "Bearer ff6332ba0b844332b8e78e0a31472a0f";

const QAToken = localStorage.getItem("env");

const baseUrl =
  QAToken !== null && QAToken === "QA"
    ? "https://hireapi.devassisto.com/api"
    : "/api";
const emailNotificationBaseUrl = "https://notify.nsglobalgroup.com/";
const notificationServiceToken =
  QAToken !== null && QAToken === "QA"
    ? "Bearer 46292adfcd5b4e07b41d1bf8e60db989"
    : "Bearer 951222c933c14f66ade408a6a532327f";
const jobDescriptionEndPoint = "/jobs_page?page=:page&pageSize=:page_size";
const createNewJDEndPoint = "/create_new_jd";
const checkMatchStatusEndPoint = "/status/:job_id";
const postCheckMatchEndPoint =
  "/resume/JD_matched_Resumes/:jd_id?created_by=:created_by";
const CheckMatchEndPoint =
  "/check_match_page/:jd_id?Job_id=:job_id&page=:page&pageSize=:page_size";
const getJDDetailsById = "/jobdescription/:jd_id";
const deleteJobDescriptionEndPoint = "/jobdescription/delete/";
const viewResourceDetailsByResumeId =
  "/matched_resource_for_jd?resume_id=:resume_id&JD_id=:JD_Id";
const viewResourceDwnldEndPoint = "/resume/download/";
const downloadJobDescriptionEndPoint = "/export_pdf/";
const getJdDetailsByIdEndPoint = "/jobdescription/";
const updateJdDetailsByIdEndPoint = "/jobdescription/update/";
const employeeDetailsEndPoint =
  "/resumes_page?page=:pageNum&pageSize=:pageSize";
export const getAllSkillsEndPoint = "/get_all_skills";
export const getAllSubSkillsEndPoint = "/get_all_subskills";
const saveTemplateEndPoint = "templates/create";
const getAllTemplate = "templates/?page=1&pageSize=:pageSize";
const sendSingleTemplate = "email/send";
const sendBulkMailTemplate = "email/send-bulk";
const addSkillEndPoint = "/add_skills";

export interface IJDList {
  id: number;
  Title: string;
  minimum_experience: number;
  maximum_experience: number;
  Must_have_skills: string[];
  Nice_to_have_skills: string[];
  Matched_profiles: number;
  job_location: string;
}

export interface IJobsPageResponse {
  jd_list: IJDList[];
  page: number;
  pageSize: number;
  total_results: number;
}

export interface IAllSkillsResponse {
  skills: string;
  id: number;
}
export interface IAllSubSkillsResponse {
  subskills: string;
  search_type: string;
  id: number;
  parent_id: number;
}

export interface ISkillList {
  Experience_Required_for_skill: number;
  IsMandatory: boolean;
  Jd_id?: number;
  extras?: {
    skill: string;
    id: number;
  };
  skill: string;
  id?: number;
}

export interface IJobDescDetailsPageResponse {
  CreatedBy: string;
  Created_at?: string;
  Domain: string;
  minimum_experience: number;
  maximum_experience: number;
  No_of_projects_completed: number;
  Template_Link: number;
  Title: string;
  UpdatedAt?: string;
  UpdatedBy?: string;
  id: number;
  job_description: string;
  skill_list: ISkillList[];
  job_location: string;
}

export interface IDeleteJobResponse {
  detail: string;
}
export interface ICheckMatchRequest {
  jd_id: number;
  created_by: string;
}
export interface IStatusRequest {
  job_id: number;
}
export interface IJobsPageRequest {
  page: number;
  pageSize: number;
}
export interface IResumePageRequest {
  pageSize: number;
  pageNum: number;
}
export interface IStatusResponse {
  status: {
    created_at: string;
    Job_status: string;
    Job_type: string;
    Job_id: number;
    created_by: string;
    resumes_db_count: number;
    updated_at: string;
    resume_count: number;
  };
}
export interface ICheckMatchDetailRequest {
  Jd_id: number;
  Job_id: number;
  page_size: number;
  page_no: number;
  isLoader?: boolean;
}
export interface IJobById {
  id: number;
}
export interface IResumeDetailsById {
  JD_id: number;
  resume_id: number;
}
export interface IResumeCandidate {
  checked: boolean;
  ResumeStorePath: string;
  GithubLink: string;
  CurrentCompany: string | null;
  Resumename: string;
  LinkedinLink: string;
  Source: string | null;
  NameOfCandidate: string | null;
  Certification: string | null;
  CurrentLocation: string | null;
  CurrentDesignation: string | null;
  Awards: string | null;
  PreferredLocation: string | null;
  TotalYearsOfExperience: string | null;
  DateOfBirth: string | null;
  UpdatedAt: string;
  WorkingDomain: string | null;
  MobileNumber: string[];
  Job_Source: string;
  id: number;
  Qualification: string | null;
  email: string;
  extras: {
    language: string[];
    awards: string[];
  };
  SpeakingLanguage: string | null;
}

export interface IResumesPageResponse {
  id: number;
  page: number;
  pageSize: number;
  total_results: number;
  data: IResumeCandidate[];
}
export interface ICheckMatchResponse {
  status: {
    updated_at: string | null;
    Job_status: string;
    Job_type: string;
    Job_id: number;
    created_at: string;
    created_by: string;
    resumes_db_count: number;
    resume_count: number;
  };
}

export interface IsaveTemplateRequest {
  name: string;
  content: any;
  server_config: number;
  client_type: string;
  is_temporary: boolean;
}
export interface IsaveTemplateResponse {
  status_message: string;
  page: number;
  pageSize: number;
  totalResult: number;
  data: {
    id: number;
    name: string;
    content: string;
    client_type: string;
    server_config: 1;
    identifier: string;
    created_at: string;
    is_active: boolean;
  };
}

export interface ITemplate {
  id: number;
  name: string;
  content: string;
  client_type: string;
  server_config: number;
  identifier: string;
  created_at: string;
  is_active: boolean;
  // subject: string;
}

export interface IgetAllTemplateResponse {
  status_message: string;
  totalResult: number;
  data: ITemplate[];
}

export interface IsendMailRequest {
  template: string | null;
  placeholder_values?: {};
  mail_server_id: number;
  to: string[];
  cc?: string[];
  bcc?: string[];
  subject: string;
  content: any;
  attachments?: IEmailAttachements[];
}
export interface IEmailAttachements {
  name: string;
  content: string;
}
export interface IsendMailResponse {
  detail: [
    {
      loc: [string, 0];
      msg: string;
      type: string;
    }
  ];
}

export interface IsendBulkMailRequest {
  template: string | null;
  requests: [
    {
      template: string | null;
      placeholder_values?: {};
      to: string[];
      cc: string[];
      bcc: string[];
      subject: string;
      content: any;
      mail_server_id: number;
      attachments?: [{ name: string; content: string }];
    }
  ];
}
export interface IGetTemplateRequest {
  pageSize: number;
}
export interface IsendBulkMailResponse {
  detail: [
    {
      loc: ["string", 0];
      msg: "string";
      type: "string";
    }
  ];
}
export interface IAddSkillRequest {
  data: {
    skill: string;
    subskill: string[];
  }[];
}
export interface IAddSkillResponse {
  job_status_id: number;
}
export interface IJobDetailsResponse {
  JD_ID: number;
  message: string;
}
export const addJobDetails = async (
  data: IJobDetails
): Promise<IJobDetailsResponse> => {
  const response = await Post<IJobDetailsResponse, IJobDetails>(
    baseUrl + createNewJDEndPoint,
    data
  );
  return response.data;
};
export const addSkillList = async (
  data: IAddSkillRequest
): Promise<IAddSkillResponse> => {
  const response = await Post<IAddSkillResponse, IAddSkillRequest>(
    baseUrl + addSkillEndPoint,
    data
  );
  return response.data;
};

export const getJobsPage = async (
  data: IJobsPageRequest
): Promise<IJobsPageResponse[]> => {
  const endPoint =
    baseUrl +
    jobDescriptionEndPoint
      .replace(":page", data.page.toString())
      .replace(":page_size", data.pageSize.toString());
  const response = await Get<IJobsPageResponse[], IJobsPageRequest>(
    endPoint,
    undefined,
    {},
    false
  );
  return response;
};

export const doCheckMatch = async (
  data: ICheckMatchRequest
): Promise<ICheckMatchResponse> => {
  const endPoint =
    baseUrl +
    postCheckMatchEndPoint
      .replace(":jd_id", data.jd_id.toString())
      .replace(":created_by", data.created_by);
  const response = await Post<ICheckMatchResponse, ICheckMatchRequest>(
    endPoint
  );
  return response.data;
};

export const getStatusCheck = async (
  job_id: string
): Promise<ICheckMatchResponse> => {
  const endPoint =
    baseUrl + checkMatchStatusEndPoint.replace(":job_id", job_id);
  const response = await Get<ICheckMatchResponse, undefined>(
    endPoint,
    undefined,
    {},
    false
  );
  return response;
};

export const getCheckMatch = async (
  data: ICheckMatchDetailRequest
): Promise<ICandidates> => {
  const endPoint =
    baseUrl +
    CheckMatchEndPoint.replace(":jd_id", data.Jd_id.toString())
      .replace(":job_id", data.Job_id.toString())
      .replace(":page", data.page_no.toString())
      .replace(":page_size", data.page_size.toString());
  const response = await Get<ICandidates, ICheckMatchDetailRequest>(
    endPoint,
    undefined,
    {},
    data.isLoader
  );
  return response;
};

export const getJdDetailsById = async (data: IJobById): Promise<IJdDetails> => {
  const endPoint =
    baseUrl + getJDDetailsById.replace(":jd_id", data.id.toString());
  const response = await Get<IJdDetails, IJobById>(endPoint);
  return response;
};

export const getCandidateDetailsbyId = async (
  data: IResumeDetailsById
): Promise<IResourceData> => {
  const endPoint =
    baseUrl +
    viewResourceDetailsByResumeId
      .replace(":resume_id", data.resume_id?.toString())
      .replace(":JD_Id", data.JD_id?.toString());
  const response = await Get<IResourceData, IResumeDetailsById>(endPoint);
  return response;
};

export const deleteJobDescription = async (id: number): Promise<number> =>
  await Delete<number>(baseUrl + deleteJobDescriptionEndPoint + id.toString());

export const downloadCandidateDetails = async (id: number): Promise<Blob> =>
  await Get<Blob, number>(
    baseUrl + viewResourceDwnldEndPoint + id.toString(),
    undefined,
    {
      responseType: "blob",
    }
  );

export const getResumesPage = async (
  data: IResumePageRequest
): Promise<IResumesPageResponse[]> => {
  const endPoint =
    baseUrl +
    employeeDetailsEndPoint
      .replace(":pageNum", data.pageNum.toString())
      .replace(":pageSize", data.pageSize.toString());
  const response = await Get<IResumesPageResponse[], IResumePageRequest>(
    endPoint,
    data
  );
  return response;
};

export const downloadJobDescription = async (id: number): Promise<Blob> =>
  await Get<Blob, number>(
    baseUrl + downloadJobDescriptionEndPoint + id.toString(),
    undefined,
    {
      responseType: "blob",
    }
  );
export const getJobDetailsById = async (
  id: number
): Promise<IJobDescDetailsPageResponse> =>
  await Get<IJobDescDetailsPageResponse, undefined>(
    baseUrl + getJdDetailsByIdEndPoint + id.toString()
  );

export const updateJdDetailsById = async (
  data: IJobDetails,
  id: number
): Promise<string> =>
  await Put<string, IJobDetails>(
    baseUrl + updateJdDetailsByIdEndPoint + id.toString(),
    data
  );

export const getAllSkills = async (): Promise<IAllSkillsResponse[]> =>
  await Get<IAllSkillsResponse[], undefined>(baseUrl + getAllSkillsEndPoint);

export const getAllSubSkills = async (): Promise<IAllSubSkillsResponse[]> =>
  await Get<IAllSubSkillsResponse[], undefined>(
    baseUrl + getAllSubSkillsEndPoint
  );

export const saveTemplate = async (
  data: IsaveTemplateRequest
): Promise<IsaveTemplateResponse> => {
  const endPoint = emailNotificationBaseUrl + saveTemplateEndPoint;
  const response = await Post<IsaveTemplateResponse, IsaveTemplateRequest>(
    endPoint,
    data,
    {
      headers: {
        Authorization: notificationServiceToken,
      },
    }
  );
  return response.data;
};

export const getTemplate = async (
  data: IGetTemplateRequest
): Promise<IgetAllTemplateResponse> => {
  const endPoint =
    emailNotificationBaseUrl +
    getAllTemplate.replace(":pageSize", data.pageSize.toString());

  const response = await Get<IgetAllTemplateResponse, IGetTemplateRequest>(
    endPoint,
    undefined,
    {
      headers: {
        Authorization: notificationServiceToken,
      },
      responseType: "json",
    }
  );
  return response;
};

export const sendMails = async (
  data: IsendMailRequest
): Promise<IsendMailResponse> => {
  const endPoint = emailNotificationBaseUrl + sendSingleTemplate;
  const response = await Post<IsendMailResponse, IsendMailRequest>(
    endPoint,
    data,
    {
      headers: {
        Authorization: notificationServiceToken,
      },
    }
  );
  return response.data;
};
console.log("QAToken", QAToken);

export const sendBulkMails = async (
  data: IsendBulkMailRequest
): Promise<IsendBulkMailResponse> => {
  const endPoint = emailNotificationBaseUrl + sendBulkMailTemplate;
  const response = await Post<IsendBulkMailResponse, IsendBulkMailRequest>(
    endPoint,
    data,
    {
      headers: {
        Authorization: notificationServiceToken,
      },
    }
  );
  return response.data;
};
