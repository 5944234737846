import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import logo from "../../assets/svgs/AddJobImg.svg";
// import { Quill } from "react-quill";
// import BlotFormatter from "quill-blot-formatter";

export interface IHandleEmailContent {
  handleEmailContent: (content: string) => void;
  isCheckMatchedTemplate: any;
}
const EmailForm = (props: IHandleEmailContent) => {
  // Quill.register("modules/blotFormatter", BlotFormatter);
  const utf8Bytes = new TextEncoder().encode(props.isCheckMatchedTemplate);
  const base64Content = atob(
    String.fromCharCode.apply(null, Array.from(utf8Bytes))
  );
  let template = base64Content;

  const [emailContent, setEmailContent] = useState<any>(template);
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "attachment"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
    ],
    // blotFormatter: {},
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
    "background",
    "link",
    "image",
    "align",
    "attachment",
  ];

  useEffect(() => {
    const utf8Bytes = new TextEncoder().encode(props.isCheckMatchedTemplate);
    const base64Content = atob(
      String.fromCharCode.apply(null, Array.from(utf8Bytes))
    );
    setEmailContent(base64Content);
    props.handleEmailContent(emailContent);
  }, [props.isCheckMatchedTemplate]);

  useEffect(() => {
    props.handleEmailContent(emailContent);
  }, [emailContent]);

  return (
    <>
      <ReactQuill
        value={emailContent}
        onChange={setEmailContent}
        placeholder="Compose your email..."
        modules={modules}
        formats={formats}
      />
    </>
  );
};

export default EmailForm;
