import { useState, useEffect } from "react";
import { Breadcrumb, Button, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getResumesPage,
  type IResumeCandidate,
} from "../../../services/jobsAPIService";
import { employeeEmailPreview, jobsPath } from "../../../utils/routes";
import Toaster, { type IToasterProps } from "../../../widgets/Toaster";
import { JobModuleTable } from "../../../widgets/Table/JobModuleTable";
import Pagination from "../../../widgets/Pagination";
import { ColumnFilterModal } from "../../../widgets/ColumnFilterModal";
import { EmailInputValue } from "../../../widgets/CustomMultiSelect";
import { validEmailPattern } from "../../../utils/regexExpressions";
import { filterValidEmails } from "../../../utils/emailValidation";

const defaultColumns = ["Resumename", "MobileNumber", "email", "Job_Source"];

export const extractName = (email: string): string => {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    return email.substring(0, atIndex);
  }
  return email;
};

export default function EmployeeDetails(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [resumeData, setResumeData] = useState<IResumeCandidate[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showSendEmailBtn, setShowSendEmailBtn] = useState(false);
  const [colums, setColumns] = useState(false);
  let checkedRows: IResumeCandidate[] = [];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    sessionStorage.getItem("newAddedColumn")
      ? JSON.parse(sessionStorage.getItem("newAddedColumn")!)
      : defaultColumns
  );
  const [newAddedColumn, setNewAddedColum] = useState<string[]>(
    sessionStorage.getItem("newAddedColumn")
      ? JSON.parse(sessionStorage.getItem("newAddedColumn")!)
      : defaultColumns
  );
  const [isColumnBlanck, setIsColumnBlanck] = useState<boolean>(false);
  const [columnOptionList, setColumnOptionList] = useState<string[]>();

  const [singleEmailArray, setSingleEmailArray] = useState<EmailInputValue[]>(
    []
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);
  const [toasters, setToasters] = useState<IToasterProps[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let { state } = useLocation();
  const [clearAllEmails, setClearAllEmails] = useState<boolean>(
    state.clearSelectedEmail || false
  );
  const [showMessage, setshowMessage] = useState(false);
  const addToast = (toast: IToasterProps) => {
    setToasters((prevToasters) => [...prevToasters, toast]);
  };

  const handleRowsPerPageChange = (e: any) => {
    setSingleEmailArray([]);
    const selectedRowsPerPage =
      e.target.value === "all" ? totalRecords : parseInt(e.target.value);
    setRowsPerPage(selectedRowsPerPage);
  };

  useEffect(() => {
    const selectedEmailsString = localStorage.getItem("selectedEmails");
    if (selectedEmailsString !== null) {
      const selectedEmails = JSON.parse(selectedEmailsString);
      clearAllEmails
        ? setSingleEmailArray([])
        : setSingleEmailArray(selectedEmails);
      setShowSendEmailBtn(selectedEmails.length > 0);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedEmails", JSON.stringify(singleEmailArray));
    setShowSendEmailBtn(singleEmailArray.length > 0);
  }, [singleEmailArray]);

  useEffect(() => {
    getResumesPage({
      pageNum: currentPageNumber,
      pageSize: rowsPerPage,
    })
      .then((resp: any) => {
        const updatedResumeData: IResumeCandidate[] = resp?.data?.map(
          (data: IResumeCandidate) => ({
            ...data,
            MobileNumber: data.MobileNumber.join(", ") || "-",
            checked:
              data.email !== null &&
              singleEmailArray.some(
                (emailObj) => emailObj.value === data.email
              ),
          })
        );

        setResumeData(updatedResumeData);
        setColumnOptionList(
          Object.keys(resp.data[0]).filter((key) => key !== "extras")
        );
        setTotalRecords(resp.total_results);

        setSingleEmailArray((prevEmails) => {
          const previousEmails = prevEmails
            .filter((emailObj) => emailObj.value)
            .map((emailObj) => emailObj.value);
          const currentEmails = updatedResumeData
            .filter((data: IResumeCandidate) => data.checked && data.email)
            .map((data: IResumeCandidate) => data.email);
          const uniqueEmailsSet = new Set([
            ...previousEmails,
            ...currentEmails,
          ]);

          const validEmails = Array.from(uniqueEmailsSet).filter((email) =>
            validEmailPattern.test(email)
          );

          return validEmails.map((email) => ({
            value: email,
            label: email,
            isSelected: true,
          }));
        });
      })
      .catch(() => {
        addToast({
          id: 1,
          message: "Details Not Found",
          variant: "danger",
        });
      });
  }, [currentPageNumber, rowsPerPage]);

  useEffect(() => {
    checkedRows = [];
    checkedRows = resumeData.filter((d) => d.checked);
    setSelectAll(checkedRows.length === resumeData.length);
  }, [resumeData]);

  const getAllData = async () => {
    await getResumesPage({
      pageNum: currentPageNumber,
      pageSize: rowsPerPage,
    })
      .then((resp: any) => {
        const updatedResumeData: IResumeCandidate[] = resp?.data?.map(
          (data: IResumeCandidate) => ({
            ...data,
            MobileNumber: data.MobileNumber.join(", ") || "-",
            checked: selectAll ? true : false,
          })
        );

        let emailArray = singleEmailArray;

        updatedResumeData.forEach((data: IResumeCandidate) => {
          emailArray.push({
            value: data.email,
            label: data.email,
            isSelected: true,
          });
        });
        let uniqueEmails = filterValidEmails(emailArray);
        setSingleEmailArray(uniqueEmails);
      })
      .catch(() => {
        addToast({
          id: 1,
          message: "Error selecting all Data",
          variant: "danger",
        });
      });
  };

  const handleSelectAllCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const checked = event.target.checked;
    const updatedResumeData = resumeData.map((d) => ({
      ...d,
      checked: checked,
    }));

    if (!checked) {
      const updatedResumeData = resumeData.map((d) => ({
        ...d,
        checked: checked || false,
      }));

      const currentPageEmailsSet = new Set(
        resumeData
          .filter((data: IResumeCandidate) => data.checked)
          .map((data: IResumeCandidate) => data.email)
      );

      const uniqueCurrentPageEmails = Array.from(currentPageEmailsSet);

      setSingleEmailArray((prevEmails) =>
        prevEmails.filter(
          (emailObj) => !uniqueCurrentPageEmails.includes(emailObj.value)
        )
      );

      // Uncheck email IDs on the current page
      const updatedResumeDataUncheck = updatedResumeData.map((d) => ({
        ...d,
        checked: false,
      }));
      setResumeData(updatedResumeDataUncheck);
      setshowMessage(false);
    } else {
      getAllData();
      setshowMessage(true);
    }

    setSelectAll(checked);
    setShowSendEmailBtn(checked);
    if (checked || !resumeData.length) {
      setResumeData(updatedResumeData);
    }
  };

  const handleSelectCheckBox = (
    data: IResumeCandidate,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (!event.target.checked) {
      setSelectAll(false);
    }

    const tempData = resumeData.map((details: IResumeCandidate) => {
      if (details.id === data.id) {
        let tempArray: EmailInputValue[] = [...singleEmailArray];
        if (event.target.checked) {
          tempArray.push({
            value: details.email,
            label: details.email,
            isSelected: true,
          });
        } else {
          tempArray = tempArray.filter((item) => item.value !== details.email);
        }

        setSingleEmailArray(tempArray);
        setResumeData((prevState) => {
          return prevState.map((prevDetails) =>
            prevDetails.id === details.id
              ? { ...details, checked: event.target.checked }
              : prevDetails
          );
        });

        if (totalRecords === tempArray.length) {
          setSelectAll(true);
        }
      }
      return details;
    });
  };

  const handleColumnChange = (
    index: number,
    options: string | undefined
  ): void => {
    const updatedColumns = [...selectedColumns];
    updatedColumns[index] = options || "";
    setSelectedColumns(updatedColumns);
  };

  const handleAddOptions = (): void => {
    if (selectedColumns.some((col) => col === "")) {
      setIsColumnBlanck(true);
      return;
    }
    setNewAddedColum(selectedColumns);
    setColumns(false);
    setIsColumnBlanck(false);
    sessionStorage.setItem("newAddedColumn", JSON.stringify(selectedColumns));
  };

  const handleAddColumn = (): void => {
    if (selectedColumns.some((col) => col === "")) {
      setIsColumnBlanck(true);
      return;
    }
    setIsColumnBlanck(false);

    setSelectedColumns([...selectedColumns, ""]);
  };

  const handleRemoveColumn = (index: number): void => {
    const updatedColumns = selectedColumns.filter((_, i) => i !== index);
    setSelectedColumns(updatedColumns);
    setIsColumnBlanck(false);
  };
  const handleHide = (): void => {
    setColumns(false);
    setIsColumnBlanck(false);

    const storedColumns = sessionStorage.getItem("newAddedColumn");
    setSelectedColumns(
      storedColumns ? JSON.parse(storedColumns) : defaultColumns
    );
  };

  const onPageNumberClick = (pageNumber: number | null): void => {
    setCurrentPageNumber(pageNumber!);
    setClearAllEmails(false);
  };

  const handleEmailPreview = () => {
    if (
      singleEmailArray.length <= 1 &&
      (singleEmailArray[0].value === null ||
        /^[.,\s]/.test(singleEmailArray[0].value))
    ) {
      addToast({
        id: 1,
        message: t(
          "JOBS_PAGE.THE_CHOSEN_CANDIDATE_DOES_NOT_POSSESS_AN_EMAIL_ADDRESS"
        ),

        variant: "danger",
      });
    } else if (singleEmailArray.length > 1000) {
      addToast({
        id: 2,
        message: t("JOBS_PAGE.ONLY_RECORDS_CAND_BE_SELECTED"),
        variant: "danger",
      });
    } else {
      navigate(employeeEmailPreview, {
        state: {
          email: singleEmailArray,
        },
      });
    }
  };

  useEffect(() => {
    if (state?.message !== undefined) {
      addToast({
        id: 111,
        message: state?.message,
        variant: "success",
      });
    }
  }, [state]);

  return (
    <>
      {toasters.map((toast) => (
        <Toaster key={toast.id} {...toast} />
      ))}
      <Container>
        <Row className="mx-3 my-2">
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate(jobsPath);
              }}
            >
              {t("JOBS_PAGE.JOBS")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <strong>{t("JOBS_PAGE.EMPLOYEE_DETAILS")}</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>

      <div className="container-fluid d-flex justify-content-center mt-2">
        <div className="col-xl-11 col-lg-11 col-md-11 col-12 col-sm-12">
          <div className="row m-0  justify-content-around">
            <div>{`No. of Valid Emails Count ${singleEmailArray.length} `}</div>
            {showMessage && (
              <div>
                <p className="fw-bold" style={{ color: "blue" }}>
                  {t("JOBS_PAGE.UNIQUE_EMAILS")}
                </p>
              </div>
            )}
            <div className="row mt-3 me-0  ps-3">
              <div className="col-md-6 p-0">
                <div>
                  <label className="me-3">{t("JOBS_PAGE.ROWS")}</label>
                  <select
                    id="rowsPerPage"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    className="p-2 "
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    {/* <option value="all">{t("JOBS_PAGE.ALL")}</option> */}
                  </select>
                </div>
              </div>
              <div className="col-md-6 pe-0">
                <div className="row ms-0 ps-0 justify-content-md-end">
                  {showSendEmailBtn && (
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-10 col-10  ">
                      <Button
                        data-testid="send-email-button"
                        variant="dark"
                        onClick={handleEmailPreview}
                        className="jobs-page-container__create-new-btn "
                      >
                        {t("JOBS_PAGE.SEND_EMAIL")}
                      </Button>
                    </div>
                  )}
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-10 col-10  ">
                    <Button
                      data-testid="back-button"
                      variant="dark"
                      onClick={() => {
                        setColumns(true);
                      }}
                      className="jobs-page-container__create-new-btn "
                    >
                      {t("JOBS_PAGE.COLUMN_OPTIONS")}
                    </Button>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-10 col-10 pe-0">
                    <Button
                      data-testid="back-button"
                      variant="dark"
                      onClick={() => {
                        navigate(jobsPath);
                      }}
                      className="jobs-page-container__create-new-btn "
                    >
                      {t("JOBS_PAGE.BACK")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div data-testid="employee-table">
            <JobModuleTable
              data={resumeData}
              handleSelectCheckBox={handleSelectCheckBox}
              tableHeader={[
                <input
                  key="checkbox"
                  data-testid="email-checkbox"
                  type="checkbox"
                  onChange={handleSelectAllCheckBox}
                  checked={selectAll}
                />,
                "Sl.No",
                ...(newAddedColumn.length > 0
                  ? Array.from(newAddedColumn)
                  : Array.from(selectedColumns)),
              ]}
              currentPage={currentPageNumber}
              rowsPerPage={rowsPerPage}
              tableMessage="No Data Available"
            />
          </div>
          <div className="row justify-content-end align-items-center pt-4 pb-5">
            <div className="col-xl-7 col-lg-6 col-md-10 col-sm-10 col-12">
              <div className="d-flex justify-content-end align-items-center">
                {/* <label className="mx-3">{t("JOBS_PAGE.ROWS")}</label>
                <select
                  id="rowsPerPage"
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  className="p-2 "
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="all">{t("JOBS_PAGE.ALL")}</option>
                </select> */}

                <Pagination
                  currentPageNumber={currentPageNumber}
                  totalRecords={totalRecords}
                  pageSize={rowsPerPage}
                  border={true}
                  onPageNumberClick={onPageNumberClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      <ColumnFilterModal
        show={colums}
        onHide={() => handleHide()}
        defaultColumns={selectedColumns}
        columnOptionList={columnOptionList ?? []}
        handleColumnChange={handleColumnChange}
        handleRemoveColumn={handleRemoveColumn}
        handleAddColumn={handleAddColumn}
        handleAddOptions={handleAddOptions}
        isBlank={isColumnBlanck}
      ></ColumnFilterModal>
    </>
  );
}
