import { ReactNode } from "react";
import Header from "../Header";

interface ILayout {
  children: ReactNode;
}
const Layout = (props: ILayout) => {
  const { children } = props;
  return (
    <>
      <Header />
      {children}
    </>
  );
};
export default Layout;
