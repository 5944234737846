/* eslint-disable no-useless-escape */
export const alphaNumericWithSpaceOnlySpecialCharPattern = /^[A-Za-z0-9\s]*$/
export const alphaNumericWithAllSpecialCharsPattern = /^[A-Za-z0-9\W_]*$/
export const alphaNumericOnlyPattern = /^[a-zA-Z0-9\s]*$/
export const validEmailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
export const alphaNumericWithCapitalizedCharPattern = /^[A-Z0-9]+$/
export const tenDigitMobileNumberPattern = /^[0-9]{10}$/
export const digitsOnly = /^[0-9]*$/
export const piCodePattern = /^(?!0)\d{4}(?:\d{2})?$/
export const eightDigitStrongPasswordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const nameWithUprLowrSpaceBetNumbersPattern1 =
  /^[0-9A-Za-z]+(?: [0-9A-Za-z]+)*$/
export const domainName = /^(?!-+$)[a-z0-9-]+$/
export const nameWithUprLowrSpaceBetPattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/
export const nameWithUprLowrSpaceBetNumbersPattern2 =
  /^(?!^[0-9]+$)[0-9A-Za-z]+(?: [0-9A-Za-z]+)*$/
export const employeeIdAlphabetFormatPattern = /^([A-Z]{3,4})$/
export const mobileNumberPattern1 = /^\+91\d{10,13}$/
export const mobileNumberPattern2 = /^\+{1}\d{12,15}$/
export const mobileNumberPattern3 = /^[0-9A-Za-z]+(?: [0-9A-Za-z]+)*$/
export const pfNumberValidation =
  /^[A-Z]{2}[\s\/]?[A-Z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7}$/
export const isWhitespace = (value: string): boolean => {
  return /^\s*$/.test(value)
}
export const panRegex = /^([A-Z]{5})(\d{4})([A-Z])$/
export const contactNumberTenDigitWithoutCode = /^\d{10}$/
export const aadharNumberTwelveDigit = /^\d{12}$/
export const nameWithUprLowrSpaceBetNumbersPattern =
  /^(?!^[0-9]+$)[0-9A-Za-z]+(?: [0-9A-Za-z]+)*$/
export const mobileNumberPattern = /^\+91\d{10,13}$/
export const alphaNumericForAddress = /^(?!^[0-9]+$)(?!^[^a-zA-Z\d]+$)(?!^[^a-zA-Z]+$).+$/
export const urlRegex = /^(https?|ftp):\/\/([^\s/$.?#].[^\s]*)$/i;
