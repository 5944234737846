import { ReactNode, useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  FloatingLabel,
  Form,
  Modal,
} from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomMultiSelect, { EmailInputValue } from "../CustomMultiSelect";
import EmailForm from "../MailFunctions";
import {
  ITemplate,
  IgetAllTemplateResponse,
  IsendBulkMailRequest,
  IsendMailRequest,
  getTemplate,
  saveTemplate,
  sendBulkMails,
  sendMails,
} from "../../services/jobsAPIService";
import Logo from "../../assets/svgs/assisto-logo.svg";
import Toaster, { IToasterProps } from "../Toaster";
import {
  checkMatchPath,
  employeeDetailsPath,
  employeeEmailPreview,
  jobsPath,
} from "../../utils/routes";
import { filterValidEmails } from "../../utils/emailValidation";

export interface IEmailPreview {
  singleEmailArray: string;
}

interface IEmailCheckMatch {
  emailsFromCheckMatch: any;
  isCheckMatched?: boolean;
  childern?: ReactNode;
  subject: string;
}

export interface ITemplateOptions {
  id: number;
  name: string;
  content: string;
  client_type: string;
  server_config: number;
  identifier: string;
  created_at: string;
  is_active: boolean;
}

export interface IBase64Data {
  fileName: string;
  fileContent: string;
}

const EmailPreview = (props: IEmailCheckMatch): JSX.Element => {
  const [CcView, setCcView] = useState(false);
  const [fileView, setfileView] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const [toaster, setToaster] = useState<IToasterProps>({
    showToast: false,
  });
  const [templateName, setTemplateName] = useState<string>("");
  let uniqueEmails: any = filterValidEmails(location.state.email);

  const [message, setMessage] = useState({
    to: uniqueEmails,
    cc: [],
    bcc: "",
    subject: "",
    content: "",
  });
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [templateOptions, setTemplateOptions] = useState<ITemplateOptions>(
    templates[0]
  );
  const [previewContent, setPreviewContent] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const navigate = useNavigate();
  const [base64Data, setBase64Data] = useState<IBase64Data[]>([]);

  useEffect(() => {
    getAllTemplateList();
  }, []);

  const getAllTemplateList = async () => {
    await getTemplate({ pageSize: 1000 })
      .then((res: IgetAllTemplateResponse) => {
        setTemplates([...res.data]);
        setTemplateOptions(res.data[0]);
        setMessage({ ...message, subject: res.data[0].name });
      })
      .catch((errr: any) => {
        setToaster({
          ...toaster,
          message: "Failed to fetch Templates",
          variant: "danger",
          showToast: true,
        });
      });
  };

  const handleClickCC = () => {
    const buttoncc: any = document.querySelector(".ccbutton");
    buttoncc.classList.add("ccHide");
    setCcView(true);
  };

  const handleFileAdd = (e: any) => {
    const buttonBcc: any = document.querySelector(".bccbutton");
    buttonBcc.classList.add("ccHide");
    setfileView(true);
  };

  const handleEmailContent = (content: string): void => {
    setPreviewContent(content);
    const utf8Bytes = new TextEncoder().encode(content);
    const base64Content = btoa(
      String.fromCharCode.apply(null, Array.from(utf8Bytes))
    );
    setMessage({ ...message, content: base64Content });
  };

  const handleChange = (e: any) => {
    setMessage({ ...message, subject: e.target.value });
  };

  const handleOptionChange = (e: any) => {
    const selectedValue = e.target.value;

    const selectedOption = templates.find(
      (option) => option.name === selectedValue
    );

    if (selectedOption) {
      setTemplateOptions(selectedOption);
      setMessage({ ...message, subject: selectedOption.name });
    }
  };

  const handleSend = async () => {
    if (btoa(previewContent) !== templateOptions?.content) {
      const templatePayload = {
        name: templateName,
        content: message.content,
        subject: message.subject,
        server_config: 1,
        client_type: "EMAIL",
        is_temporary: true,
      };
      try {
        await saveTemplate(templatePayload);
      } catch {
        setToaster({
          ...toaster,
          message: "Unable to save the email template.",
          variant: "danger",
          showToast: true,
        });
      }
    }

    let singleEmailData: IsendMailRequest = {
      template: null,
      placeholder_values: {},
      content: message.content,
      mail_server_id: 1,
      to: message.to.map((t: EmailInputValue) => t.value),
      cc: message.cc.map((t: EmailInputValue) => t.value),
      bcc: [],
      subject: message.subject,
      attachments: base64Data.map((data: IBase64Data) => ({
        name: data.fileName,
        content: data.fileContent,
      })),
    };

    try {
      if (message.to.length === 1) {
        const response = await sendMails(singleEmailData);
        if (response) {
          const currentPath = window.location.pathname;
          const pathToNavigate =
            currentPath === employeeEmailPreview
              ? employeeDetailsPath
              : checkMatchPath;

          navigate(pathToNavigate, {
            state: {
              message:
                "Mails sent to Message Queue. Kindly check your email sent items to check the status.",
              job_id: location.state?.job_id,
              jd_ID: location.state?.jd_ID,
            },
          });
          localStorage.removeItem("selectedEmails");
        } else {
          setToaster({
            ...toaster,
            message: "Unable to send an email",
            variant: "danger",
            showToast: true,
          });
        }
      } else if (message.to.length > 1) {
        if (message.to.length > 1000) {
          setToaster({
            ...toaster,
            message: t("JOBS_PAGE.ONLY_RECORDS_CAND_BE_SELECTED"),
            variant: "danger",
            showToast: true,
          });
        } else {
          sendEmailBulk();
        }
      } else {
        setToaster({
          ...toaster,
          message: "Unable to send an email",
          variant: "danger",
          showToast: true,
        });
      }
    } catch (err) {
      setToaster({
        ...toaster,
        message: "Unable to send an email",
        variant: "danger",
        showToast: true,
      });
    }
  };

  const sendEmailBulk = async () => {
    const templatePayload = {
      name: templateName || templateOptions.name,
      content: message.content,
      server_config: 1,
      client_type: "EMAIL",
      is_temporary: true,
    };
    try {
      const templateResp = await saveTemplate(templatePayload);
      if (templateResp.status_message === "SUCCESS") {
        getAllTemplateList();
        const templateRequests = message.to.map((emailId: EmailInputValue) => ({
          template: templateResp.data.identifier,
          placeholder_values: {},
          content: null,
          mail_server_id: 1,
          to: [emailId.value],
          cc: message.cc.map((t: EmailInputValue) => t.value),
          bcc: [],
          subject: message.subject,
          attachments: base64Data.map((data: IBase64Data) => ({
            name: data.fileName,
            content: data.fileContent,
          })),
        }));
        let bulkEmailData: IsendBulkMailRequest = {
          template: templateResp.data.identifier,
          requests: templateRequests,
        };
        const response = await sendBulkMails(bulkEmailData);
        if (response) {
          const currentPath = window.location.pathname;
          const pathToNavigate =
            currentPath === employeeEmailPreview
              ? employeeDetailsPath
              : checkMatchPath;
          navigate(pathToNavigate, {
            state: {
              message:
                "Mails sent to Message Queue. Kindly check your email sent items to check the status.",
              job_id: location.state?.job_id,
              jd_ID: location.state?.jd_ID,
            },
          });
          localStorage.removeItem("selectedEmails");
        } else {
          setToaster({
            ...toaster,
            message: "Bulk email sending limit is exceeded for Today",
            variant: "danger",
            showToast: true,
          });
        }
      } else {
        setToaster({
          ...toaster,
          message: "Unable to send an email",
          variant: "danger",
          showToast: true,
        });
      }
    } catch (err) {
      setToaster({
        ...toaster,
        message: "Bulk email sending limit is exceeded for Today",
        variant: "danger",
        showToast: true,
      });
    }
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };
  const handlePreview = () => {
    setShowPreview(!showPreview);
  };

  const handleDisable = () => {
    if (message.content === "PHA+PGJyPjwvcD4=") {
      return true;
    } else if (message.to.length === 0) {
      return true;
    } else if (message.subject === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleTemplateCreate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value);
  };

  const handleSave = async () => {
    const templatePayload = {
      name: templateName,
      content: message.content,
      subject: message.subject,
      server_config: 1,
      client_type: "EMAIL",
      is_temporary: false,
    };
    try {
      const response = await saveTemplate(templatePayload);
      if (response.status_message === "SUCCESS") {
        getAllTemplateList();
        handleModal();
        setToaster({
          ...toaster,
          message: "Template Saved Successusfully!",
          variant: "success",
          showToast: true,
        });
      }
    } catch {
      setToaster({
        ...toaster,
        message: "Unable to save Template",
        variant: "warning",
        showToast: true,
      });
    }
  };

  const handleUpdateEmailsData = (updatedEmails: any) => {
    setMessage({
      ...message,
      to: updatedEmails,
    });
  };

  const handleCcData = (updatedEmails: any) => {
    setMessage({
      ...message,
      cc: updatedEmails,
    });
  };

  const emailContents = () => {
    const cleanedContent = previewContent
      .toString()
      .replace(/<br\s*\/?>/gi, "");
    return <div dangerouslySetInnerHTML={{ __html: cleanedContent }}></div>;
  };

  // Function to handle file processing
  const handleFileChange = (event: any) => {
    const files = event.target.files;
    const allowedExtensions = /\.(pdf|doc|docx|xls|xlsx)$/i;
    const maxSize = 10 * 1024 * 1024;
    const newFileData = [...base64Data];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!allowedExtensions.test(file.name)) {
        setError(
          "Invalid file type. Only PDF, DOC, and DOCX files are allowed."
        );
        event.target.value = "";
        return;
      }

      if (file.size > maxSize) {
        setError("File size exceeds the limit of 10MB.");
        event.target.value = "";
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const fileData = (reader.result as string).split(",")[1];
        newFileData.push({
          fileName: file.name,
          fileContent: fileData,
        });
        setBase64Data(newFileData);
      };

      reader.readAsDataURL(file);
    }

    setError("");
  };

  return (
    <>
      {(toaster.showToast ?? false) && (
        <Toaster
          showToast={toaster.showToast}
          message={toaster.message}
          variant={toaster.variant}
        />
      )}
      <Container className="">
        <Row className=" my-3">
          {/* <Col>{props.childern}</Col> */}
          <Col className="p-0">
            <div className="mt-3">{`No. Emails selected ${message.to.length} `}</div>

            <div className="mt-3 fw-bold" style={{ color: "blue" }}>
              {t("JOBS_PAGE.UNIQUE_EMAILS")}
            </div>
          </Col>

          <Col className="col-md-5 p-0">
            <FloatingLabel
              className="w-100 m-0"
              controlId="floatingSelect"
              label="Select Templates"
            >
              <Form.Select
                aria-label="Floating label select example"
                className="w-100"
                onChange={(e) => {
                  handleOptionChange(e);
                }}
                value={templateOptions?.name}
              >
                {templates.map((options: any, index: number) => (
                  <option key={index} value={options.name}>
                    {options.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
      </Container>
      <Container className="mt-2 border p-3">
        <Row className="mt-3">
          <Col>
            {/* <div className="d-flex align-items-center">
              <Button className="px-5">{t("JOBS_PAGE.SEND")}</Button>
              <p className="px-4 ms-3 mb-0 border rounded py-1 align-middle">
                {t("JOBS_PAGE.FROM")}
              </p>
              <Form.Control
                type="email"
                value={"Hiring@nsplustech.com"}
                className="input__outline outline-0 border-0 ms-3 w-100"
              />
            </div> */}
            <Row>
              <Col xl={10} lg={10} md={8} xs={12} className="pe-0">
                <div className="d-flex">
                  <p className="px-4 mb-0 recipient border rounded py-1 align-middle">
                    {t("JOBS_PAGE.RECIPIENT")}
                  </p>
                  <div className="w-100 ms-3">
                    <CustomMultiSelect
                      emails={location.state.email}
                      handleUpdateEmailsData={handleUpdateEmailsData}
                    />
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={2} md={4} xs={12} className="p-0">
                <div className="">
                  <Button
                    onClick={handleClickCC}
                    className="border-dark bg-transparent text-dark ccbutton  px-3 py-1"
                  >
                    Cc
                  </Button>
                  <Button
                    onClick={handleFileAdd}
                    className="border-dark bg-transparent text-dark bccbutton ms-2 px-4 py-1"
                  >
                    Add File
                  </Button>
                </div>
              </Col>
            </Row>

            {CcView && (
              <>
                <hr></hr>
                <div className="row">
                  <div className="d-flex ">
                    <p className="px-4 mb-0 recipient border rounded py-1 align-middle">
                      Cc
                    </p>
                    <div className="w-100 ms-4">
                      <CustomMultiSelect
                        handleUpdateEmailsData={handleCcData}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {fileView && (
              <>
                <hr></hr>
                <div className="row">
                  <div className="d-flex ">
                    <p className="px-4 recipient mb-0 border rounded py-1 align-middle">
                      Files
                    </p>
                    <div className="w-100 ms-3">
                      <input
                        type="file"
                        placeholder="Please add attachement"
                        accept=".pdf,.doc,.docx,.xls,.xlsx"
                        multiple
                        onChange={handleFileChange}
                      />
                      {error && <div className="error_text pt-2">{error}</div>}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* <Form.Control
                type="email"
                value={location?.state}
                className="input__outline outline-0 border-0 ms-4 w-100"
              /> */}
            <hr className="mb-1"></hr>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder={t("JOBS_PAGE.ADD_A_SUBJECT")}
              id="subject"
              maxLength={100}
              name="subject"
              value={message.subject}
              className="input__outline border-0"
              onChange={handleChange}
            />
            <hr className="mt-1"></hr>
            <EmailForm
              handleEmailContent={handleEmailContent}
              isCheckMatchedTemplate={templateOptions?.content}
            />

            <div className="d-flex justify-content-end">
              <button
                onClick={handlePreview}
                disabled={message.content === "PHA+PGJyPjwvcD4="}
                className="outline rounded px-5"
              >
                Preview
              </button>
              <Modal
                show={showPreview}
                className="p-4 modal-xl"
                centered
                onHide={() => setShowPreview(!showPreview)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h5 className="m-0">Preview Screen</h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="p-1 d-flex justify-content-end">
                    <img className="img-fluid " src={Logo} alt="hireassisto" />
                  </div>
                  <div className="p-4">{emailContents()}</div>
                </Modal.Body>
              </Modal>
              <Button
                disabled={message.content === "PHA+PGJyPjwvcD4="}
                onClick={handleModal}
                className="btn btn-dark px-5 mx-3"
              >
                Save Template
              </Button>
              <Modal
                show={showModal}
                className="p-4 modal-lg"
                centered
                onHide={() => setShowModal(!showModal)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h5 className="m-0">Save Template</h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="p-4">
                    <FloatingLabel label="Template Name">
                      <Form.Control
                        id="skills"
                        type="text"
                        placeholder="Template Name"
                        value={templateName}
                        onChange={handleTemplateCreate}
                      />
                    </FloatingLabel>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="text-white px-5 bg-dark"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    className="bg-transparent border text-dark px-5"
                    onClick={() => setShowModal(!showModal)}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
              <Button
                onClick={handleSend}
                disabled={handleDisable()}
                className="btn btn-dark px-5"
              >
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmailPreview;
