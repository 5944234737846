import {
  Breadcrumb,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  checkMatchPath,
  jobsPath,
} from "../../../../utils/routes";
import EmailPreview from "../../../../widgets/EmailPreview";


function CheckMatchEmailPreview() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      <Container className="mt-3">
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate(jobsPath);
              }}
            >
              {t("JOBS_PAGE.JOBS")}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => {
                navigate(checkMatchPath, {
                  state: {
                    jd_ID: location.state.jd_ID,
                    job_id: location.state.job_id,
                    clearSelectedEmail:true

                  },
                });
              }}
            >
              {t("JOBS_PAGE.CHECK_MATCH")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <strong>{t("JOBS_PAGE.SEND_EMAIL")}</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        {/* <Row>
              <Col></Col>
              <Col className="col-md-5">
                <FloatingLabel controlId="floatingSelect" label="Select Templates">
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={getAllTemplateList}
                  >
                    <option></option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row> */}
        <Row>
          <Col>
            <EmailPreview
              emailsFromCheckMatch={location.state.email}
              subject="Check Match Profile"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CheckMatchEmailPreview;
