import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Logo from "../../assets/svgs/Logo.svg";
import Logo from "../../assets/svgs/assisto-logo.svg";
import Prev from "../../assets/svgs/prev-icon.svg";
import Settings from "../../assets/svgs/settings-icon.svg";
import Bell from "../../assets/svgs/bell-icon.svg";

const Header = () => {
  const homeNavItems = [{ title: "Home", to: "/" }];
  // const candidatesNavItems = [{ title: "Candidates", to: "/candidates" }];
  const jobsNavItems = [
    { title: "Jobs List", to: "/" },
    // { title: "Resumes", to: "#" },
    // { title: "Settings", to: "#" },
  ];
  // const myInfoNavItems = [
  //   { title: "Personal", to: "#" },
  //   { title: "Time Sheet", to: "#" },
  //   { title: "Time Tracker", to: "#" },
  //   { title: "Calender", to: "#" },
  //   { title: "Projects", to: "#" },
  //   { title: "Assets", to: "#" },
  //   { title: "More", to: "#" },
  // ];

  const navBrands = [
    { source: Bell, to: "#" },
    { source: Settings, to: "#" },
    { source: Prev, to: "#" },
  ];

  const bottomNavItems = [
    { title: "Jobs", to: "/jobs" },
    // { title: "Resumes", to: "#" },
    // { title: "Settings", to: "#" },
  ];

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        className="p-1 underlined"
        data-testid="header-component-wrapper"
      >
        <Container fluid>
          <Navbar.Brand href="#">
            <img className="img-fluid" src={Logo} alt=""></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll ">
            <Nav
              className="me-auto my-2 my-lg-0 mx-5 d-flex p-0 mx-3 pe-3"
              navbarScroll
            >
              <Nav.Item>
                {homeNavItems.map((item) => {
                  return (
                    <Nav.Link href={item.to} className="py-2 me-3">
                      {item.title}
                    </Nav.Link>
                  );
                })}
              </Nav.Item>

              {/* <Nav.Item>
                {candidatesNavItems.map((item) => {
                  return (
                    <Nav.Link href={item.to} className="py-2 mx-lg-3">
                      {item.title}
                    </Nav.Link>
                  );
                })}
              </Nav.Item> */}

              <NavDropdown
                title="Jobs"
                id="basic-nav-dropdown"
                className="pe-3"
              >
                {jobsNavItems.map((item) => {
                  return (
                    <Nav.Link href={item.to} className="py-1 mx-3">
                      {item.title}
                    </Nav.Link>
                  );
                })}
              </NavDropdown>
            </Nav>
            {/* <Form className="d-flex mx-5">
              <input
                type="search"
                placeholder="Search"
                className="search-btn px-3 py-2"
                aria-label="Search"
              />
            </Form>
            {navBrands.map((brand) => {
              return (
                <Navbar.Brand href={brand.to}>
                  <img src={brand.source} alt="" className="mx-2"></img>
                </Navbar.Brand>
              );
            })} */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
