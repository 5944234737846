import { Button } from "react-bootstrap";
import { ReactNode } from "react";

interface IButtonProps {
  label: string | ReactNode;
  variant: string;
  icon: ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement>) => any;
  className?: string;
  testid?: string;
}

export default function ButtonWithIcon(props: IButtonProps) {
  return (
    <div className="d-flex p-0 justify-content-end">
      <Button
        variant={props.variant}
        onClick={props.onClick}
        className={props.className}
        data-testid={props.testid}
      >
        {props.icon}
        {props.label}
      </Button>
    </div>
  );
}
