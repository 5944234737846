import React from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  FloatingLabel,
  Container,
} from "react-bootstrap";
import { HiPlus } from "react-icons/hi";
import { MdOutlineClear } from "react-icons/md";
import Select, { type SingleValue } from "react-select";
import { useTranslation } from "react-i18next";

interface IColumnFilterProps {
  show: boolean;
  onHide: () => void;
  defaultColumns: string[];
  columnOptionList: string[];
  handleColumnChange: (index: number, value: string | undefined) => void;
  handleRemoveColumn: (index: number) => void;
  handleAddColumn: () => void;
  handleAddOptions: () => void;
  isBlank?: boolean;
}

interface ISelectOptions {
  value: string | undefined;
  label: string | undefined;
}

export const ColumnFilterModal = (props: IColumnFilterProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      data-testid="general-modal"
      contentClassName="jobs-page-container__medium-modal"
    >
      <Modal.Header className="mx-3" closeButton>
        <Modal.Title> {t("JOBS_PAGE.COLUMN_OPTIONS")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="jobs-page-container__modal-scrollable jobs-page-container__modal-scrollable__flexscroll">
        <Row className="px-3">
          {props.defaultColumns.map((colData: string, index: number) => {
            const selectedOption = props.columnOptionList?.find(
              (option: string) => option === colData
            );
            const updatedOptions = props.columnOptionList?.filter(
              (option: string) => !props.defaultColumns.includes(option)
            );

            return (
              <React.Fragment key={index}>
                <Col xl={10} lg={10} md={10} xs={10}>
                  <FloatingLabel label="" className="mt-4">
                    <Select
                      onChange={(
                        newValue: SingleValue<ISelectOptions | undefined>
                      ) => {
                        const selectOption = newValue as
                          | ISelectOptions
                          | undefined;
                        const value =
                          selectOption !== null && selectOption !== undefined
                            ? selectOption.value
                            : undefined;
                        props.handleColumnChange(index, value);
                      }}
                      value={{
                        value: selectedOption,
                        label: selectedOption,
                      }}
                      options={updatedOptions?.map((list: string) => ({
                        value: list,
                        label: list,
                      }))}
                      isClearable={false}
                      placeholder=""
                    />
                  </FloatingLabel>
                </Col>
                {index >= 4 ? ( // Render remove icon only for columns beyond the first four
                  <Col
                    xl={1}
                    lg={1}
                    md={1}
                    xs={1}
                    className="align-self-center mx-1 mt-4"
                  >
                    <MdOutlineClear
                      id="remove"
                      onClick={() => {
                        props.handleRemoveColumn(index);
                      }}
                      className="createNewJob__plus-sign-button"
                    />
                  </Col>
                ) : null}
              </React.Fragment>
            );
          })}
        </Row>

        <Row>
          {props.isBlank && (
            <div className="createNewJob-error-text mx-3 mt-2">
              {t("JOBS_PAGE.PLEASE_SELECT_COLUMN")}
            </div>
          )}
          <Col className="mx-3 my-5 " xl={4} lg={4} md={4} xs={10}>
            <div className="d-flex hoverEffect align-items-center">
              <HiPlus />
              <div className="px-2" onClick={props.handleAddColumn}>
                {t("JOBS_PAGE.ADD_COLUMN")}
              </div>
            </div>
          </Col>
        </Row>

        <Container className="justify-content-end d-flex  ">
          <Col xl={2} lg={2} md={3} xs={4} className="p-0 mx-3 ">
            <Button
              onClick={props.handleAddOptions}
              data-testid="save-button"
              className="createNewJob__saveBtn"
            >
              {t("JOBS_PAGE.OK")}
            </Button>
          </Col>
          <Col xl={2} lg={2} md={3} xs={4} className="p-0  ">
            <Button
              data-testid="cancel-btn"
              className="createNewJob__CancelBtn"
              onClick={props.onHide}
            >
              {t("JOBS_PAGE.CANCEL")}
            </Button>
          </Col>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
