import Accordion from "react-bootstrap/Accordion";
import { ReactNode } from "react";

export interface IAccordionProps {
  defaultActiveKey?: string;
  className?: string;
  accordionHeader?: string;
  heading: string | ReactNode;
  children: ReactNode;
  eventKey: string;
}

export default function Accordian(props: IAccordionProps) {
  return (
    <Accordion defaultActiveKey="1" alwaysOpen className={props.className}>
      <Accordion.Item eventKey="1">
        <Accordion.Header className={props.accordionHeader}>
          {props.heading}
        </Accordion.Header>
        <Accordion.Body className="p-0">{props.children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
