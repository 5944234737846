import { Modal, Button } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GrDocumentPdf } from 'react-icons/gr'
interface IDownloadJdPopupProps {
  id: number
  showExportPopup: boolean
  downloadJD: (e: React.MouseEvent<HTMLElement>) => void
  radioHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  setShowExportPopup: (close: boolean) => void
}

export default function DownloadJdPopup(
  props: IDownloadJdPopupProps,
): JSX.Element {
  const { t } = useTranslation()
  return (
    <Modal data-testid="download-job-popup" show={props.showExportPopup}>
      <div className="px-5 py-4 del-card mt-2">
        <GrDocumentPdf />
        <span className="template-radio-button-text">
          {t('JOBS_PAGE.SELECT_TEMPLATE')}
        </span>
        <div className="row mx-3 mt-4">
          <div className="m-2">
            <input
              className="m-1"
              type="radio"
              data-testid="templateOne"
              id="templateOne"
              name="one"
              value="templateone"
              onChange={props.radioHandler}
            />
            <label>{t('JOBS_PAGE.TEMPLATE_ONE')}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 my-2">
            <Button
              data-testid="download-jd-button"
              onClick={props.downloadJD}
              className="download-button bg-dark shadow-1-strong border-dark px-4 py-2 mt-3">
              {t('JOBS_PAGE.DOWNLOAD_JD')}
            </Button>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 my-2">
            <Button
              data-testid="cancel-button"
              onClick={() => {
                props.setShowExportPopup(false)
              }}
              className="cancel-button bg-light shadow-1-strong text-dark border-dark px-5 py-2 mt-3">
              {t('JOBS_PAGE.CANCEL')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
