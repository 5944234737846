import { Toast, ToastContainer } from "react-bootstrap";
import React, { useState } from "react";
import { MdClose } from "react-icons/md"; 

export interface IToasterProps {
  id?: number;
  message?: string;
  variant?: string;
  showToast?: boolean;
}

export default function Toaster(props: IToasterProps) {
  const [showToast, setToast] = useState(props.showToast);

  const handleClose = () => {
    setToast(false);
  };

  return (
    <div className="d-flex justify-content-end">
      <ToastContainer className="p-3">
        <Toast
          onClose={handleClose}
          autohide
          show={showToast}
          delay={6000}
          bg={props.variant}
        >
          <Toast.Body className="d-flex justify-content-between align-items-center">
            <span className="me-4">{props.message}</span>
            <MdClose onClick={handleClose} className="createNewJob__close-button" />
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
 