import JobsPage from "../pages/Jobs";
import CreateNewJob from "../pages/Jobs/CreateNewJob";
import Layout from "../widgets/layouts";
import CheckMatch from "../pages/Jobs/CheckMatch";
import ViewResource from "../pages/Jobs/ViewResource";
import EmployeeDetails from "../pages/Jobs/EmployeeDetails";
import FindDuplicate from "../pages/Jobs/FindDuplicate";
import EmailPreview from "../widgets/EmailPreview";
import EmployeeEmailPreview from "../pages/Jobs/EmployeeDetails/EmployeeEmailPreview";
import CheckMatchEmailPreview from "../pages/Jobs/CheckMatch/CheckMatchEmailPreview";

export const jobsPath = "/";
export const createNewJobPath = "/new-job";
export const editJobPath = "/edit-job";
export const checkMatchPath = "/check-Match";
export const viewResourcePath = "/view-resource";
export const employeeDetailsPath = "/employee-details";
export const findDuplicatePath = "/find-duplicate";
export const emailPreviewPath = "/email-preview"
export const employeeEmailPreview = "/employee-email-preview"
export const checkMatchEmailPreview = "/check-match-email-preview"

export interface IRouteConfig {
  path: string;
  component: React.ElementType;
  layout?: React.ElementType;
}

export const routesConfig: IRouteConfig[] = [

  {
    path: jobsPath,
    component: JobsPage,
    layout: Layout,
  },
  {
    path: createNewJobPath,
    component: CreateNewJob,
    layout: Layout,
  },
  {
    path: editJobPath,
    component: CreateNewJob,
    layout: Layout,
  },
  {
    path: checkMatchPath,
    component: CheckMatch,
    layout: Layout,
  },
  {
    path: viewResourcePath,
    component: ViewResource,
    layout: Layout,
  },
  {
    path: employeeDetailsPath,
    component: EmployeeDetails,
    layout: Layout,
  },
  {
    path: findDuplicatePath,
    component: FindDuplicate,
    layout: Layout,
  },
  {
    path: emailPreviewPath,
    component: EmailPreview,
    layout: Layout,
  },
  {
    path: employeeEmailPreview,
    component:  EmployeeEmailPreview,
    layout: Layout,
  },
  {
    path: checkMatchEmailPreview,
    component:  CheckMatchEmailPreview,
    layout: Layout,
  }
];
