/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Container, Col, Breadcrumb, Row, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getCheckMatch,
  getJdDetailsById,
  getStatusCheck,
  ICheckMatchResponse,
} from "../../../services/jobsAPIService";
import {
  checkMatchEmailPreview,
  jobsPath,
  viewResourcePath,
} from "../../../utils/routes";
import { useTranslation } from "react-i18next";

import { JobModuleTable } from "../../../widgets/Table/JobModuleTable";
import Toaster, { type IToasterProps } from "../../../widgets/Toaster";
import { ColumnFilterModal } from "../../../widgets/ColumnFilterModal";
import Pagination from "../../../widgets/Pagination";
import moment from "moment";
import { EmailInputValue } from "../../../widgets/CustomMultiSelect";
import { filterValidEmails } from "../../../utils/emailValidation";

export interface ISkill {
  jd_id: number;
  experience_required_for_skill: number;
  extras: object;
  id: number;
  IsMandatory: boolean;
  skill: string;
}

export interface IJdDetails {
  id: number;
  Domain: string;
  minimum_experience: number;
  maximum_experience: number;
  Template_Link: number;
  Created_at: string;
  UpdatedAt: string;
  No_of_projects_completed: number;
  Title: string;
  job_description: string;
  CreatedBy: string;
  UpdatedBy: string;
  skill_list: ISkill[];
}
export interface ICandidate {
  TotalYearsOfExperience: string;
  no_of_projects: number;
  WorkingDomain: string;
  last_modified: string;
  Resume_Name: string;
  Matched_Skills: string[];
  Matched_nice_to_have_Skills: string;
  must_have_percentage: number;
  jd_id: number;
  job_id: number;
  Matched_must_have_Skills: string;
  id: number;
  aggregate_percentage: number;
  nice_to_have_percentage: number;
  resume_id: number;
  MobileNumber: string[];
  email: string;
  Job_Source: string;
  checked: boolean;
}

export interface ICandidates {
  created_at?: string;
  candidates: ICandidate[];
  matched_profiles?: number;
}
const defaultColumns: string[] = [
  "Resume_Name",
  "MobileNumber",
  "email",
  "Matched_Skills",
  "Job_Source",
];

const CheckMatch = (): JSX.Element => {
  const navigate = useNavigate();
  const [candidatesData, setCandidatesData] = useState<ICandidates>();
  const [jdDetails, setJdDetails] = useState<IJdDetails>();

  const [showSendEmailBtn, setShowSendEmailBtn] = useState(false);
  const [colums, setColumns] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    sessionStorage.getItem("newCheckMatchColumn")
      ? JSON.parse(sessionStorage.getItem("newCheckMatchColumn")!)
      : defaultColumns
  );
  const [newAddedColumn, setNewAddedColum] = useState<string[]>(
    sessionStorage.getItem("newCheckMatchColumn")
      ? JSON.parse(sessionStorage.getItem("newCheckMatchColumn")!)
      : defaultColumns
  );
  const [isColumnBlanck, setIsColumnBlanck] = useState<boolean>(false);

  const [columnOptionList, setColumnOptionList] = useState<string[]>();

  const [currentPageNumber, setCurrentPageNumber] = useState(
    parseInt(localStorage.getItem("currentPageNumber") ?? "1", 10)
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const state = useLocation().state;
  const { t } = useTranslation();
  const DATE_FORMAT_DD_MM_YYYY = " DD-MM-YYYY HH:mm A";
  const dateToFormatedString = (date: any, format: any) => {
    return moment(date).format(format);
  };

  const [selectedEmailArray, setSelectedEmailArray] = useState<
    EmailInputValue[]
  >([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let checkedRows: ICandidate[] | undefined;
  const [toasters, setToasters] = useState<IToasterProps[]>([]);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [extractionMessage, setExtractionMessage] = useState<boolean>(false);
  const [extractionResponse, setExtractionResponse] =
    useState<ICheckMatchResponse>();
  const [clearAllEmails, setClearAllEmails] = useState<boolean>(
    state.clearSelectedEmail || false
  );
  const addToast = (toast: IToasterProps) => {
    setToasters((prevToasters) => [...prevToasters, toast]);
  };

  useEffect(() => {
    getJdDetailsById({
      id: state.jd_ID,
    }).then((response) => {
      setJdDetails(response);
    });
    getStatus();
  }, []);

  useEffect(() => {
    fetchCheckMatchDetails();
    setExtractionMessage(false);
  }, [currentPageNumber, rowsPerPage]);

  useEffect(() => {
    checkedRows = [];

    if (checkedRows) {
      checkedRows = candidatesData?.candidates.filter((d) => d.checked);
      checkedRows &&
        setSelectAll(checkedRows.length === candidatesData?.candidates.length);
    }
  }, [selectedEmailArray, candidatesData]);
  const [stopInterval, setStopInterval] = useState(false);

  useEffect(() => {
    if (extractionResponse?.status.Job_status !== "In_progress") {
      setExtractionMessage(false);
      setStopInterval(false);
    }
    let fetchIntervalId: any;
    setDisplayLoader(false);
    if (stopInterval) {
      fetchIntervalId = setInterval(() => {
        getStatus();
      }, 10000);
    } else {
      clearInterval(fetchIntervalId);
      setStopInterval(false);
    }

    return () => clearInterval(fetchIntervalId);
  }, [extractionResponse, candidatesData, currentPageNumber]);

  const handleRowsPerPageChange = (e: any) => {
    setSelectedEmailArray([]);
    const selectedRowsPerPage =
      e.target.value === "all" ? totalRecords : parseInt(e.target.value);
    setRowsPerPage(selectedRowsPerPage);
  };

  useEffect(() => {
    const selectedEmailsString = localStorage.getItem(
      "selectedCheckMatchEmails"
    );
    if (selectedEmailsString !== null) {
      const selectedEmails = JSON.parse(selectedEmailsString);
      clearAllEmails
        ? setSelectedEmailArray([])
        : setSelectedEmailArray(selectedEmails);
      setShowSendEmailBtn(selectedEmails.length > 0);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "selectedCheckMatchEmails",
      JSON.stringify(selectedEmailArray)
    );
    setShowSendEmailBtn(selectedEmailArray.length > 0);
  }, [selectedEmailArray]);

  const getStatus = async () => {
    let resumneJobid = sessionStorage.getItem("jobid");
    if (resumneJobid !== null) {
      const response = await getStatusCheck(resumneJobid!);
      if (response.status.Job_status === "In_progress") {
        setStopInterval(true);
        setExtractionMessage(false);
        setExtractionResponse(response);
        setDisplayLoader(false);
      } else {
        setStopInterval(false);
        setExtractionResponse(response);
        setExtractionMessage(true);
      }

      fetchCheckMatchDetails();
    }
  };
  let intervalId: any;
  const fetchCheckMatchDetails = async () => {
    try {
      const checkMatchResponse: ICandidates = await getCheckMatch({
        Jd_id: state.jd_ID,
        Job_id: state.job_id,
        page_size: rowsPerPage,
        page_no: currentPageNumber,
        isLoader: displayLoader,
      });

      if (checkMatchResponse) {
        const updatedCandidatesData = {
          candidates: checkMatchResponse.candidates.map((candidate: any) => ({
            ...candidate,
            last_modified: getTimeAgo(candidate.last_modified),
            MobileNumber: candidate.MobileNumber.join(", ") || "-",
            Matched_must_have_Skills:
              candidate.Matched_must_have_Skills.join(", ") || "-",
            Matched_Skills: candidate.Matched_Skills.join(", ") || "-",
            checked:
              candidate.email !== null &&
              selectedEmailArray.some(
                (emailObj) =>
                  emailObj.value.toLocaleLowerCase() ===
                  candidate.email.toLocaleLowerCase()
              ),
          })),
          created_at: checkMatchResponse.created_at,
          matched_profiles: checkMatchResponse.matched_profiles,
        };

        setColumnOptionList(
          checkMatchResponse &&
            checkMatchResponse.candidates &&
            checkMatchResponse.candidates.length > 0
            ? Object.keys(checkMatchResponse.candidates[0])
            : selectedColumns
        );

        setTotalRecords(checkMatchResponse.matched_profiles!);

        if (updatedCandidatesData.candidates.length > 0) {
          if (candidatesData?.candidates) {
            clearInterval(intervalId!);

            if (selectedEmailArray.length > 0) {
              updatedCandidatesData.candidates.forEach(
                (candidate: ICandidate) => {
                  candidate.checked =
                    candidate.email !== null &&
                    selectedEmailArray.some(
                      (emailObj) => emailObj.value === candidate.email
                    );
                }
              );
            }
          }

          setCandidatesData(updatedCandidatesData);
          setSelectedEmailArray((prevEmails) => {
            const previousEmails = prevEmails.map((emailObj) => emailObj.value);
            const currentEmails =
              updatedCandidatesData &&
              updatedCandidatesData.candidates
                .filter((data: ICandidate) => data.checked && data.email)
                .map((data: ICandidate) => data.email);

            const uniqueEmails = Array.from(
              new Set([...previousEmails, ...currentEmails])
            );

            // Check if all checkboxes on the current page are checked
            const allChecked = updatedCandidatesData?.candidates.every(
              (data: ICandidate) => data.checked
            );

            // Set the "Select All" checkbox state based on whether all checkboxes on the current page are checked
            setSelectAll(allChecked);

            return uniqueEmails.map((email) => ({
              value: email,
              label: email,
              isSelected:
                prevEmails.some((emailObj) => emailObj.value === email) ||
                currentEmails.includes(email),
            }));
          });
        }
      }
    } catch (error) {
      console.error("Error fetching check match details:", error);
    }
  };

  useEffect(() => {
    let intervalCount = 0;
    if (extractionResponse?.status.Job_status !== "In_progress") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setExtractionMessage(false);
      setStopInterval(false);
      intervalId = setInterval(() => {
        fetchCheckMatchDetails();
        intervalCount++;
        if (intervalCount === 2) {
          clearInterval(intervalId);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [extractionResponse]);

  const getAllEmailData = async () => {
    let emailArray: EmailInputValue[] = [];
    const checkMatchResponse = await getCheckMatch({
      Jd_id: state.jd_ID,
      Job_id: state.job_id,
      page_size: rowsPerPage,
      page_no: currentPageNumber,
      isLoader: true,
    });
    if (checkMatchResponse) {
      setTotalRecords(checkMatchResponse.matched_profiles!);
      const updatedCandidatesData: ICandidates = {
        ...candidatesData,
        candidates: candidatesData!.candidates.map((d: ICandidate) => ({
          ...d,
          checked: true,
        })),
      };

      setCandidatesData(updatedCandidatesData);
      updatedCandidatesData?.candidates.forEach((data: ICandidate) => {
        emailArray.push({
          value: data.email,
          label: data.email,
          isSelected: true,
        });
      });
    } else {
      addToast({
        id: 1,
        message: "Failed to selecting all Data",
        variant: "danger",
      });
    }
    let uniqueEmailsArray = filterValidEmails(emailArray);
    setSelectedEmailArray([...selectedEmailArray, ...uniqueEmailsArray]);
  };

  const getTimeAgo = (startDate: any) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const now = new Date();
    const timeDiff = now.getTime() - new Date(startDate).getTime();
    const daysDiff = Math.ceil(timeDiff / oneDay);
    const monthsDiff = Math.floor(daysDiff / 30);
    const monthStr = monthsDiff > 1 ? "Months" : "Month";
    const yearsDiff = Math.floor(daysDiff / 365);
    const yearStr = yearsDiff > 1 ? "Years" : "Year";
    return daysDiff > 365
      ? `${yearsDiff} ${yearStr} Ago`
      : daysDiff > 30
      ? `${monthsDiff} ${monthStr} Ago`
      : `${daysDiff} Day${daysDiff > 1 ? "s" : ""} Ago`;
  };

  const handleSelectAllCheckBox = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const checked = event.target.checked;

    if (!checked) {
      const currentPageEmails = candidatesData!.candidates
        .filter((data: ICandidate) => data.checked)
        .map((data: ICandidate) => data.email);

      const updatedSelectedEmailArray = selectedEmailArray.filter(
        (emailObj) => !currentPageEmails.includes(emailObj.value)
      );

      setSelectedEmailArray(updatedSelectedEmailArray);

      const updatedCandidatesDataUncheck: ICandidates = {
        ...candidatesData,
        candidates: candidatesData!.candidates.map((d: ICandidate) => ({
          ...d,
          checked: false,
        })),
      };
      setCandidatesData(updatedCandidatesDataUncheck);
    } else {
      await getAllEmailData();
    }

    setSelectAll(checked);
    setShowSendEmailBtn(checked);
  };

  const handleSelectCheckBox = (
    data: ICandidate,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (!event.target.checked) {
      setSelectAll(false);
    }

    const updatedCandidatesData = {
      candidates:
        candidatesData?.candidates.map((d: ICandidate) => {
          if (d.id === data.id) {
            d.checked = event.target.checked;
            let tempArray: EmailInputValue[] = [...selectedEmailArray];

            if (event.target.checked) {
              tempArray.push({
                value: d.email,
                label: d.email,
                isSelected: true,
              });
            } else {
              tempArray = tempArray.filter((item) => item.value !== d.email);
            }

            setSelectedEmailArray(tempArray);
          }
          return d;
        }) ?? [],
    };

    setCandidatesData({
      ...candidatesData,
      candidates: updatedCandidatesData.candidates,
    });

    const checkedRows = updatedCandidatesData.candidates.filter(
      (d: ICandidate) => d.checked
    );

    setShowSendEmailBtn(checkedRows.length > 0);
  };

  const handleColumnChange = (
    index: number,
    options: string | undefined
  ): void => {
    const updatedColumns = [...selectedColumns];
    updatedColumns[index] = options || "";
    setSelectedColumns(updatedColumns);
  };

  const handleAddOptions = (): void => {
    if (selectedColumns.some((col) => col === "")) {
      setIsColumnBlanck(true);
      return;
    }
    setNewAddedColum(selectedColumns);
    setColumns(false);
    setIsColumnBlanck(false);
    sessionStorage.setItem("newAddedColumn", JSON.stringify(selectedColumns));
  };

  const handleAddColumn = (): void => {
    if (selectedColumns.some((col) => col === "")) {
      setIsColumnBlanck(true);
      return;
    }
    setIsColumnBlanck(false);

    setSelectedColumns([...selectedColumns, ""]);
  };

  const handleRemoveColumn = (index: number): void => {
    const updatedColumns = selectedColumns.filter((_, i) => i !== index);
    setSelectedColumns(updatedColumns);
    setIsColumnBlanck(false);
  };
  const handleHide = (): void => {
    setColumns(false);
    setIsColumnBlanck(false);

    const storedColumns = sessionStorage.getItem("newAddedColumn");
    setSelectedColumns(
      storedColumns ? JSON.parse(storedColumns) : defaultColumns
    );
  };

  const onPageNumberClick = (pageNumber: number | null): void => {
    setCurrentPageNumber(pageNumber!);
    localStorage.setItem("currentPageNumber", pageNumber!.toString());

    setDisplayLoader(true);
    setClearAllEmails(false);
  };
  const handleEmailPreview = () => {
    if (
      selectedEmailArray.length <= 1 &&
      (selectedEmailArray[0].value === null ||
        /^[.,\s]/.test(selectedEmailArray[0].value))
    ) {
      addToast({
        id: 1,
        message: t(
          "JOBS_PAGE.THE_CHOSEN_CANDIDATE_DOES_NOT_POSSESS_AN_EMAIL_ADDRESS"
        ),
        variant: "danger",
      });
    } else if (selectedEmailArray.length > 1000) {
      addToast({
        id: 2,
        message: t("JOBS_PAGE.ONLY_RECORDS_CAND_BE_SELECTED"),
        variant: "danger",
      });
    } else {
      navigate(checkMatchEmailPreview, {
        state: {
          email: selectedEmailArray,
          jd_ID: state.jd_ID,
          job_id: state.job_id,
        },
      });
    }
  };

  const dots = Array.from({ length: 10 }, (_, index) => (
    <div key={index} style={{ "--index": index } as React.CSSProperties}></div>
  ));

  const customProperties = {
    "--duration": "2s",
    "--num-dot": 10,
  };

  const customStyles: React.CSSProperties = {
    fontSize: "100px",
    ...customProperties,
  };

  useEffect(() => {
    if (state?.message !== undefined) {
      addToast({
        id:111,
        message: state?.message,
        variant: "success",
      });
    }
  }, [state]);

  return (
    <>
      {toasters.map((toast) => (
        <Toaster key={toast.id} {...toast} />
      ))}
      {extractionResponse?.status.Job_status === "In_progress" && (
        <div className="extraction-card">
          <div className="d-flex align-items-center">
            <div className="loading" style={customStyles}>
              {dots}
            </div>
            <div className="pe-4 pt-3">
              {extractionResponse!.status.resume_count !== null && (
                <h5>
                  <span className="me-2">
                    {extractionResponse!.status.resume_count}{" "}
                  </span>
                  {t("JOBS_PAGE.RESUME_OUT_OF")}
                  <span className="ms-2">
                    {extractionResponse!.status.resumes_db_count}{" "}
                  </span>
                </h5>
              )}
              <h5>{t("JOBS_PAGE.EXTRACTION_IS_IN_PROGRESS")}</h5>
            </div>
          </div>
        </div>
      )}
      {extractionMessage && (
        <div className="extraction-card">
          <div className="d-flex align-items-center">
            <div className="px-4 py-2">
              <p className="">{t("JOBS_PAGE.DATA_EXTRACTION_IS_COMPLETED")}</p>
              <p>{t("JOBS_PAGE.COMPLETE_PROFILES_ARE_LISTED")}</p>
              <div className="d-flex justify-content-center ">
                <div className="col-3">
                  <Button
                    className="createNewJob__CancelBtn"
                    onClick={() => {
                      setStopInterval(false);
                      setExtractionMessage(false);
                    }}
                  >
                    {t("JOBS_PAGE.OK")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Container className="checkmatch">
        <Row className="mx-3 my-2">
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate(jobsPath);
              }}
            >
              {t("JOBS_PAGE.JOBS")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <strong>{t("JOBS_PAGE.CHECK_MATCH")}</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <Row className="checkmatch__descBg mx-4 d-flex  ">
          <Col xl={4} lg={3} md={6} xs={12}>
            <strong>{t("JOBS_PAGE.NEW_JOB_TITLE")}</strong>

            <div className="mt-2">{jdDetails?.Title}</div>
            <div className="mt-4">
              <strong> {t("JOBS_PAGE.EXPERIENCE")}</strong>
            </div>
            <div className="checkmatch__descBg__desc mt-2">
              {jdDetails?.minimum_experience != null
                ? `${jdDetails?.minimum_experience} - ${jdDetails?.maximum_experience}`
                : "-"}
            </div>
          </Col>

          <Col xl={4} lg={3} md={6} xs={12}>
            <strong>{t("JOBS_PAGE.MUST_HAVE_SKILL")}</strong>

            {jdDetails?.skill_list?.map((data: ISkill, i: number) => {
              return (
                <div className="mt-2" key={i}>
                  {data.IsMandatory ? data.skill : ""}
                </div>
              );
            })}
            <div className="mt-4">
              <strong> {t("JOBS_PAGE.NICE_TO_HAVE_SKILL")}</strong>
            </div>
            {jdDetails?.skill_list?.map((data: ISkill, i: number) => {
              return (
                <div className="mt-2" key={i}>
                  {!data.IsMandatory ? data.skill : ""}
                </div>
              );
            })}
          </Col>
          <Col xl={4} lg={3} md={6} xs={12}>
            <strong>{t("JOBS_PAGE.JOB_DESC")}</strong>
            <div className="checkmatch__descBg__desc mt-2">
              {jdDetails?.job_description}
            </div>
          </Col>
        </Row>

        <div className="container-fluid d-flex justify-content-center my-5">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4 col-12 col-sm-12">
                <div>
                  <div className="fw-bolder">
                    {t("JOBS_PAGE.MATCHED_PROFILE_CHECKMATCH")}
                    {candidatesData?.matched_profiles}
                  </div>
                  {`No. Emails selected ${selectedEmailArray.length} `}
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-12 col-sm-12">
                <div className="row justify-content-sm-start justify-content-md-end ">
                  {showSendEmailBtn && (
                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10 pr-3 pt-2">
                      <Button
                        data-testid="send-email-button"
                        variant="dark"
                        onClick={handleEmailPreview}
                        className="jobs-page-container__create-new-btn"
                      >
                        {t("JOBS_PAGE.SEND_EMAIL")}
                      </Button>
                    </div>
                  )}
                  {candidatesData?.matched_profiles === 0 ? (
                    <></>
                  ) : (
                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-10 col-10  pt-2">
                      <Button
                        data-testid="back-button"
                        variant="dark"
                        onClick={() => {
                          setColumns(true);
                        }}
                        className="jobs-page-container__create-new-btn"
                      >
                        {t("JOBS_PAGE.COLUMN_OPTIONS")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3 justify-content-end">
              <label className="mx-3">{t("JOBS_PAGE.ROWS")}</label>
              <select
                id="rowsPerPage"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="p-2 "
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                {/* <option value="all">{t("JOBS_PAGE.ALL")}</option> */}
              </select>
            </div>
            <div data-testid="checkMatch-table">
              <JobModuleTable
                data={candidatesData?.candidates}
                handleSelectCheckBox={handleSelectCheckBox}
                tableHeader={[
                  candidatesData?.matched_profiles === 0 ? (
                    ""
                  ) : (
                    <input
                      data-testid="email-checkbox"
                      type="checkbox"
                      onChange={handleSelectAllCheckBox}
                      checked={selectAll}
                    />
                  ),

                  "Sl.No",
                  ...(newAddedColumn.length > 0
                    ? Array.from(newAddedColumn)
                    : Array.from(selectedColumns)),
                ]}
                tableRowClick={(row: ICandidate) => {
                  navigate(viewResourcePath, {
                    state: {
                      JD_id: row.jd_id,
                      resume_id: row.resume_id,
                    },
                  });
                }}
                tableRowStyle={true}
                currentPage={currentPageNumber}
                rowsPerPage={rowsPerPage}
                tableMessage={
                  extractionResponse?.status.Job_status === "In_progress"
                    ? candidatesData &&
                      candidatesData.matched_profiles !== undefined
                      ? ""
                      : "Please Wait..."
                    : extractionResponse?.status.Job_status === "Completed" &&
                      candidatesData &&
                      candidatesData.matched_profiles !== undefined
                    ? ""
                    : "No matching profiles found"
                }
              />
            </div>
            <div className="row justify-content-between align-items-center ">
              <div className="col-xl-5 col-lg-6 col-md-10 col-sm-10 col-12  pt-4">
                <span className="fw-bolder">
                  {t("JOBS_PAGE.ALL_MATCHED_SKILLS")} (
                  {t("JOBS_PAGE.LAST_SCAN_DATE")}
                  {dateToFormatedString(
                    candidatesData?.created_at,
                    DATE_FORMAT_DD_MM_YYYY
                  )}
                  )
                </span>
              </div>
              {candidatesData?.matched_profiles === 0 ? (
                <></>
              ) : (
                <div className="col-xl-7 col-lg-6 col-md-10 col-sm-10 col-12  pt-4">
                  <div className="d-flex justify-content-end align-items-center">
                    <Pagination
                      currentPageNumber={currentPageNumber}
                      totalRecords={totalRecords}
                      pageSize={rowsPerPage}
                      border={true}
                      onPageNumberClick={onPageNumberClick}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ColumnFilterModal
          show={colums}
          onHide={() => handleHide()}
          defaultColumns={selectedColumns}
          columnOptionList={columnOptionList ?? []}
          handleColumnChange={handleColumnChange}
          handleRemoveColumn={handleRemoveColumn}
          handleAddColumn={handleAddColumn}
          handleAddOptions={handleAddOptions}
          isBlank={isColumnBlanck}
        ></ColumnFilterModal>
      </Container>
    </>
  );
};

export default CheckMatch;
