import React, { type ReactNode } from 'react'

interface ITableDataProps {
  id: number
  value: string
}
interface ITableProps {
  // change later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  tableHeader: string[] | ReactNode
  tableRowClick?: (row: ITableDataProps) => void
  tableRowStyle?: boolean
  headerActions?: ReactNode
  testid?: string
}

export const TableComponent = (props: ITableProps): JSX.Element => {
  return (
    <table
      className="table checkmatch-container__resource-table employee-list__add-employee-table jobs-page-container__table-border text-left mt-3"
      data-testid={props.testid}>
      <thead>
        <tr className="employee-list__add-employee-header">
          {Array.isArray(props.tableHeader) &&
            props.tableHeader.map((th: string) => {
              if (th === 'ellipse') {
                return <th key={1}>{props.headerActions}</th>
              } else {
                return <th key={th}>{th}</th>
              }
            })}
        </tr>
      </thead>
      <tbody>
        {props.data.map((row: ITableDataProps) => (
          <tr
            key={row.id}
            onClick={() => {
              props.tableRowClick?.(row)
            }}
            className={props.tableRowStyle ?? false ? 'hoverEffect' : ''}>
            {Object.entries(row).map(([key, value]) => {
              if (key !== 'jdId' && key !== 'resumeId') {
                return (
                  <td data-key={value} key={value}>
                    {value}
                  </td>
                )
              } else {
                return null
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
