/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getResumesPage,
  type IResumeCandidate,
  type IResumesPageResponse,
} from "../../../services/jobsAPIService";
import { jobsPath } from "../../../utils/routes";
import { TableComponent } from "../../../widgets/Table";
import Toaster, { type IToasterProps } from "../../../widgets/Toaster";
import { json } from "stream/consumers";

function FindDuplicate(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [resumeData, setResumeData] = useState<IResumeCandidate[]>();
  const [showToaster, setShowToaster] = useState(false);
  const [toaster, setToaster] = useState<IToasterProps>({
    showToast: false,
  });
  const [totalRecords, setTotalRecords] = useState(100);

  useEffect(() => {
    getResumesPage({
      pageNum: 1,
      pageSize: totalRecords,
    })
      .then((resp: any) => {
        setResumeData(resp.data);
        setTotalRecords(resp.total_results);
        if (resumeData != null && resumeData?.length > 0) {
          setShowToaster(true);
          // setToaster({
          //   ...toaster,
          //   message: "Data Fetched Successfully",
          //   variant: "success",
          //   showToast: true,
          // });
        }
      })
      .catch(() => {
        setShowToaster(true);
        setToaster({
          ...toaster,
          message: "Details Not Found",
          variant: "danger",
          showToast: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecords]);

  const tableData = resumeData
    ?.map((r: any) => ({
      ...r,
      email:
        typeof r.email === "string" && r.email.length > 0 ? r.email : "No-Info",
      MobileNumber:
        Array.isArray(r.MobileNumber) && r.MobileNumber.length > 0
          ? r.MobileNumber
          : "No-Info",
    }))
    .filter((r) => {
      if (searchTerm.length === 0) {
        return true;
      }
      if (typeof r !== "object") {
        return false;
      }
      return (
        (typeof r.email === "string" &&
          Boolean(r.email.startsWith(searchTerm))) ||
        (Array.isArray(r.MobileNumber) &&
          r.MobileNumber.findIndex((m: any) => m.startsWith(searchTerm)) > -1)
      );
    });

  return (
    <>
      {showToaster && (
        <Toaster
          showToast={toaster.showToast}
          message={toaster.message}
          variant={toaster.variant}
        />
      )}
      <Container>
        <Row className="mx-3 my-2">
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate(jobsPath);
              }}
            >
              {t("JOBS_PAGE.JOBS")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <strong>{t("JOBS_PAGE.FIND_DUPLICATE")}</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>

      <div className="container-fluid d-flex justify-content-center">
        <div className="col-xl-11 col-lg-11 col-md-11 col-12 col-sm-12">
          <div className="row p-2 justify-content-md-between justify-content-center ">
            <div className="col-xl-3 col-lg-1 col-md-6 col-sm-10 col-10 p-0 align-self-center mt-1 p-2">
              <input
                type="search"
                placeholder="Search with Phone No/Email ID"
                className="py-2 border form-control"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-10 col-10 p-0 align-self-center mt-3 px-lg-0 px-2">
              <Button
                data-testid="back-button-f"
                variant="dark"
                onClick={() => {
                  navigate(jobsPath);
                }}
                className="jobs-page-container__create-new-btn "
              >
                {t("JOBS_PAGE.BACK")}
              </Button>
            </div>
          </div>
          <Row>
            <Col xl={12}>
              <Table bordered hover className="mt-3">
                <thead className="table-header">
                  <tr className="py-2">
                    <th className="py-3 sl_no_table">{t("JOBS_PAGE.SL_NO")}</th>
                    <th className="py-3">{t("JOBS_PAGE.RESUME_NAME")}</th>
                    <th className="py-3">{t("JOBS_PAGE.MOBILE_NO")}</th>
                    <th className="py-3">{t("JOBS_PAGE.EMAIL_ID")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((user, index) => {
                    const { id, Resumename, MobileNumber, email } = user;
                    const phoneNo = Array.isArray(MobileNumber)
                      ? MobileNumber.join(", ")
                      : MobileNumber;
                    return (
                      <tr key={id} className="py-2">
                        <td className="table__data">{index + 1}</td>
                        <td className="table__data">{Resumename}</td>
                        {/* <td>{MobileNumber}</td> */}
                        <td className="table__data">
                          {Array.isArray(MobileNumber) ? (
                            <ul className="p-0">
                              {MobileNumber.map((number, i) => (
                                <li
                                  className="list-unstyled align-items-center"
                                  key={i}
                                >
                                  {number}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <li className="list-unstyled">{phoneNo}</li>
                          )}
                        </td>
                        <td className="table__data">{email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default FindDuplicate;
