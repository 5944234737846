import { Breadcrumb, Col, Container, Row, Card } from "react-bootstrap";
import { BiDownload } from "react-icons/bi";
import { checkMatchPath, jobsPath } from "../../../utils/routes";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Toaster, { type IToasterProps } from "../../../widgets/Toaster";

import {
  getCandidateDetailsbyId,
  downloadCandidateDetails,
} from "../../../services/jobsAPIService";
import { TableComponent } from "../../../widgets/Table";

interface IMustToHaveSkills {
  jd_skill: string;
  skill_match_percent: string;
  experience: string;
  match_status: string;
  no_of_projects: string;
}

interface INiceToHaveSkills {
  jd_skill: string;
  skill_match_percent: string;
  experience: string;
  match_status: string;
  no_of_projects: string;
}
export interface IResourceData {
  NameOfCandidate?: string;
  Certification?: string;
  CurrentLocation?: string;
  CurrentDesignation?: string;
  Awards?: string;
  PreferredLocation?: string;
  TotalYearsOfExperience: string;
  DateOfBirth?: string;
  UpdatedAt: string;
  WorkingDomain?: string;
  MobileNumber: string;
  reference_id?: string;
  id: number;
  Qualification?: string;
  email: string;
  extras: {
    language: string[];
    awards: string[];
  };
  ResumeStorePath: string;
  SpeakingLanguage?: string;
  CurrentCompany?: string;
  Resumename: string;
  GithubLink: string;
  Source?: string;
  Job_Source: string;
  LinkedinLink: string;
  Resume_Name: string;
  Matched_nice_to_have_Skills: string[];
  Matched_Skills: string[];
  must_have_percentage: number;
  JD_id: number;
  job_id: number;
  Matched_must_have_Skills: string[];
  aggregate_percentage: number;
  nice_to_have_percentage: number;
  resume_id: number;
  no_of_projects_completed: number;
  other_skills: string[];
  jd_must_have_skills: IMustToHaveSkills[];
  jd_nice_to_have_Skills: INiceToHaveSkills[];
}

const ViewResource = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [resourceData, setResourceData] = useState<IResourceData>();
  const [id, setId] = useState<number>(0);
  const [showToaster, setShowToaster] = useState(false);
  const [toaster, setToaster] = useState<IToasterProps>({
    showToast: false,
  });
  useEffect(() => {
    void getCandidateDetailsbyId({
      JD_id: location.state.JD_id,
      resume_id: location.state.resume_id,
    }).then((response) => {
      setResourceData(response);
    });
    setId(location.state.resume_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadCandidateResume = (fileName: string): void => {
    downloadCandidateDetails(id)
      .then((resp) => {
        let mimeType;
        const extension = fileName?.split(".").pop()?.toLowerCase() ?? "";
        if (extension === "pdf") {
          mimeType = "application/pdf";
          const file = new Blob([resp], { type: mimeType });
          const fileURL = URL.createObjectURL(file);
          const pdfWindow: Window | null = window.open();
          if (pdfWindow != null) {
            pdfWindow.location.href = fileURL;
          }
        } else if (extension === "doc" || extension === "docx") {
          mimeType = "application/msword";
          const blob = new Blob([resp], { type: mimeType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        } else {
          throw new Error("Unsupported file type");
        }
      })
      .catch((err: Error) => {
        setShowToaster(true);
        setToaster({
          ...toaster,
          message: err.message,
          variant: "danger",
          showToast: true,
        });
      });
  };

  return (
    <>
      {showToaster && (
        <Toaster
          showToast={toaster.showToast}
          message={toaster.message}
          variant={toaster.variant}
        />
      )}
      <Container data-testid="view-resource-container">
        <Row className="mx-3 my-2">
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate(jobsPath);
              }}
            >
              {t("JOBS_PAGE.JOBS")}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              data-testid="navigate-checkmatch"
              onClick={() => {
                navigate(checkMatchPath, {
                  state: {
                    jd_ID: resourceData?.JD_id,
                    job_id: resourceData?.job_id,
                    clearSelectedEmail:true

                  },
                });
              }}
            >
              {t("JOBS_PAGE.CHECK_MATCH")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <strong>{resourceData?.Resume_Name}</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>
      <div className="container d-flex justify-content-center mb-5">
        <Col xl={10} lg={10} md={12} xs={12}>
          <Card className="p-5">
            <Row className="justify-content-between mb-5">
              <Col xl={9} lg={9} md={12} xs={12}>
                <div className=" mb-2">{t("JOBS_PAGE.NAME_OF_RESOURCE")}</div>
                <h4 className="fw-bolder text-dark">
                  {resourceData?.Resume_Name}
                </h4>
              </Col>
              <Col xl={3} lg={3} md={12} xs={12} className="p-0 mt-3">
                <button
                  className="text-decoration-underline d-flex justify-content-end btn p-0 "
                  data-testid="download-profile"
                  onClick={() => {
                    downloadCandidateResume(resourceData!.Resume_Name ?? "");
                  }}
                >
                  <BiDownload size={22} />
                  <div className="fw-bolder ms-2">
                    {t("JOBS_PAGE.DOWNLOAD_PROFILE")}
                  </div>
                </button>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">
                  {t("JOBS_PAGE.MATCHED_MUST_TO_HAVE_SKILL")}
                </div>
                <div className="view-resource-data">
                  {resourceData?.Matched_must_have_Skills.map((l) => l).join(
                    ", "
                  )}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">
                  {t("JOBS_PAGE.MATCHED_NICE_TO_HAVE_SKILL")}
                </div>
                <div className="view-resource-data">
                  {resourceData?.Matched_nice_to_have_Skills.map((l) => l).join(
                    ", "
                  )}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.OTHER_SKILLS")}</div>
                <div className="view-resource-data">
                  {resourceData?.other_skills.map((l) => l).join(", ")}
                </div>
              </Col>
            </Row>
            <Row data-testid="view-resource-fields">
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">
                  {t("JOBS_PAGE.RESUME_EXPEREINCE")}
                </div>
                <div className="view-resource-data">
                  {resourceData?.TotalYearsOfExperience != null
                    ? resourceData?.TotalYearsOfExperience
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">
                  {t("JOBS_PAGE.NO_OF_PROJECTS_COMPLETED")}
                </div>
                <div className="view-resource-data" data-testid="project-data">
                  {resourceData?.no_of_projects_completed != null
                    ? resourceData?.no_of_projects_completed
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.WORKING_DOMAIN")}</div>
                <div className="view-resource-data">
                  {resourceData?.WorkingDomain != null
                    ? resourceData?.WorkingDomain
                    : "No-info"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.QUALIFICATION")}</div>
                <div className="view-resource-data">
                  {resourceData?.Qualification != null
                    ? resourceData?.Qualification
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.LANGUAGE")}</div>
                <div className="view-resource-data">
                  {resourceData?.WorkingDomain != null || "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.GIT_HUB")}</div>
                <div className="view-resource-data">
                  {resourceData?.GithubLink === undefined || null
                    ? resourceData?.GithubLink
                    : "No-info"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.CERTIFICATION")}</div>
                <div className="view-resource-data">
                  {resourceData?.Certification != null
                    ? resourceData?.Certification
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.AWARDS")}</div>
                <div className="view-resource-data">
                  {resourceData?.Awards != null
                    ? resourceData?.Awards
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.DOB")}</div>
                <div className="view-resource-data">
                  {resourceData?.DateOfBirth != null
                    ? resourceData?.DateOfBirth
                    : "No-info"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.MOBILE_NO")}</div>
                <div className="view-resource-data">
                  {resourceData?.MobileNumber != null
                    ? resourceData?.MobileNumber
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.EMAIL_ID")}</div>
                <div className="view-resource-data">
                  {resourceData?.email != null
                    ? resourceData?.email
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">
                  {t("JOBS_PAGE.CURRENT_COMPANY")}
                </div>
                <div className="view-resource-data">
                  {resourceData?.CurrentCompany != null
                    ? resourceData?.CurrentCompany
                    : "No-info"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">
                  {t("JOBS_PAGE.CURRENT_LOCATION")}
                </div>
                <div className="view-resource-data">
                  {resourceData?.CurrentLocation != null
                    ? resourceData?.CurrentLocation
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.JOB_LOCATION")}</div>
                <div className="view-resource-data">
                  {resourceData?.PreferredLocation != null
                    ? resourceData?.PreferredLocation
                    : "No-info"}
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} xs={12} className="my-4">
                <div className="fw-bolder">{t("JOBS_PAGE.JOB_SOURCE")}</div>
                <div className="view-resource-data">
                  {resourceData?.Job_Source != null
                    ? resourceData?.Job_Source
                    : "No-info"}
                </div>
              </Col>
            </Row>
          </Card>
          <div data-testid="must-have-skills">
            {resourceData != null &&
              resourceData?.jd_must_have_skills?.length > 0 && (
                <>
                  <div className="fw-bolder pt-4">
                    {t("JOBS_PAGE.MUST_HAVE_SKILL")}
                  </div>
                  <TableComponent
                    data={resourceData?.jd_must_have_skills.map((skillSet) => ({
                      JD_SKILLS: skillSet.jd_skill || "No-Info",
                      SKILL_MATCHED: skillSet.skill_match_percent || "No-Info",
                      MATCHED_NOT_MATCHED: skillSet.match_status,
                    }))}
                    tableHeader={[
                      t("JOBS_PAGE.JD_SKILLS"),
                      t("JOBS_PAGE.SKILL_MATCHED"),
                      t("JOBS_PAGE.MATCHED_NOT_MATCHED"),
                    ]}
                  />
                </>
              )}
          </div>
          {resourceData != null &&
            resourceData?.jd_nice_to_have_Skills?.length > 0 && (
              <>
                <div className="fw-bolder pt-4">
                  {t("JOBS_PAGE.NICE_TO_HAVE_SKILL")}
                </div>
                <TableComponent
                  data={resourceData?.jd_nice_to_have_Skills.map(
                    (skillSet) => ({
                      JD_SKILLS: skillSet.jd_skill || "No-Info",
                      SKILL_MATCHED: skillSet.skill_match_percent || "No-Info",
                      MATCHED_NOT_MATCHED: skillSet.match_status,
                    })
                  )}
                  tableHeader={[
                    t("JOBS_PAGE.JD_SKILLS"),
                    t("JOBS_PAGE.SKILL_MATCHED"),
                    t("JOBS_PAGE.MATCHED_NOT_MATCHED"),
                  ]}
                />
              </>
            )}
        </Col>
      </div>
    </>
  );
};

export default ViewResource;
