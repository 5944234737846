/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ReactNode } from "react";
import { useTranslation } from "react-i18next";
interface ITableProps {
  data: any;
  tableHeader?: Array<string | ReactNode>;
  tableRowClick?: (row: any) => void;
  tableRowStyle?: boolean;
  handleSelectCheckBox?: (
    data: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  currentPage: number;
  rowsPerPage: number;
  tableMessage : string;
}

export const JobModuleTable = (props: ITableProps): JSX.Element => {
  const { t } = useTranslation();

  const serialNumber = (props.currentPage - 1) * props.rowsPerPage + 1;

  return (
    <div className="jobs-page-container__table-width jobs-page-container__table-width__flexscroll">
      <table className="table checkmatch-container__resource-table jobs-page-container__table-border text-left mt-3">
        <thead>
          <tr>
            {Array.isArray(props.tableHeader) &&
              props.tableHeader.map((th: string | ReactNode, index: number) => (
                <th key={index}>{th}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {props.data && props.data.length > 0 ? (
            props.data.map((row: any, index: number) => {
              return (
                <tr key={row.id}>
                  {Array.isArray(props.tableHeader) ? (
                    props.tableHeader.map(
                      (header: string | ReactNode, headerIndex: number) => {
                        return headerIndex === 0 ? (
                          <td key={headerIndex}>
                            <input
                              data-testid="email-checkbox"
                              type="checkbox"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => props.handleSelectCheckBox?.(row, e)}
                              checked={row.checked}
                            />
                          </td>
                        ) : headerIndex === 1 ? (
                          <td key={headerIndex}>{serialNumber + index}</td>
                        ) : (
                          <td
                            key={headerIndex}
                            onClick={() =>
                              props.tableRowClick && props.tableRowClick(row)
                            }
                            className={`jobs-page-container__td-min-width ${
                              props.tableRowStyle ? "hoverEffect" : ""
                            }`}
                          >
                            {header && (row[header as string] || "No-Info")}
                          </td>
                        );
                      }
                    )
                  ) : (
                    <td> {t("JOBS_PAGE.NO_MATCHINF_PROFILE_FOUND")}</td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={
                  Array.isArray(props.tableHeader)
                    ? props.tableHeader.length + 2
                    : 1
                }
                className="text-center"
              >
                {props.tableMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
