import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RouteComponent from "./RouteComponent";
import {
  addRequestEndListener,
  addRequestStartListener,
} from "./utils/apiServices";
import { IRouteConfig, routesConfig } from "./utils/routes";

function App() {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    addRequestStartListener(() => {
      setShowLoader(true);
    });
    addRequestEndListener(() => {
      setShowLoader(false);
    });
  }, []);
  return (
    <>
      <Router>
        <Routes>
          {routesConfig.map((route: IRouteConfig) => (
            <Route
              path={route.path}
              element={
                <RouteComponent
                  layout={route.layout}
                  component={route.component}
                />
              }
            />
          ))}
        </Routes>
      </Router>
      {showLoader && (
         <div className="loader-overlay">
         <div className="loader"></div>
       </div>
      )}
    </>
  );
}

export default App;
