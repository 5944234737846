import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  doCheckMatch,
  type IJDList,
  type ICheckMatchResponse,
} from "../../services/jobsAPIService";
import { TiPuzzle } from "react-icons/ti";
import { FaEllipsisH } from "react-icons/fa";
import { MdFileDownload } from "react-icons/md";
import { RiEdit2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import { AiFillLinkedin } from "react-icons/ai";
import DownloadJdPopup from "./DownloadJdPopup/index";
import { useNavigate } from "react-router-dom";
import { checkMatchPath, editJobPath } from "../../utils/routes";
import { useTranslation } from "react-i18next";

import { TableComponent } from "../../widgets/Table";
import ConfirmationModal from "../../widgets/ConfirmationModal";

export interface IJobsTableProps {
  jobs: IJDList[];
  onDelete: (id: number) => void;
  onDownload: (id: number) => void;
  jobId?: number | null;
  currentPage?: number;
  rowsPerPage?: number;
}

export default function JobsTable(props: IJobsTableProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [id, setId] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedOption, setSelectedOption] = useState("");
  const serialNumber = (props.currentPage! - 1) * props.rowsPerPage! + 1;

  const handleCheckMatch = async (rowId: number): Promise<void> => {
    try {
      const response: ICheckMatchResponse = await doCheckMatch({
        jd_id: rowId,
        created_by: "admin",
      });

      navigate(checkMatchPath, {
        state: {
          jd_ID: rowId,
          job_id: props.jobId ? props.jobId : response?.status.Job_id,
          clearSelectedEmail:true
        },
      });
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const showDeleteJobPopup = (deleteRowId: number): void => {
    setShowPopup(true);
    setId(deleteRowId);
  };
  const downloadPopup = (downloadId: number): void => {
    setShowExportPopup(true);
    setId(downloadId);
  };

  return (
    <div className="jobs-page-container">
      <DownloadJdPopup
        id={id}
        showExportPopup={showExportPopup}
        downloadJD={() => {
          props.onDownload?.(id);
          setShowExportPopup(false);
        }}
        radioHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSelectedOption(e.target.value);
        }}
        setShowExportPopup={(close: boolean) => {
          setShowExportPopup(close);
        }}
      />
        <table className="table checkmatch-container__resource-table employee-list__add-employee-table jobs-page-container__table-border text-left mt-3">
          <thead>
            <tr className="employee-list__add-employee-header">
              <th>{t("JOBS_PAGE.SL_NO")}</th>
              <th>{t("JOBS_PAGE.JOB_TITLE")}</th>
              <th>{t("JOBS_PAGE.TOTAL_YRS_OF_EXP_PLACEHOLDER")}</th>
              <th>{t("JOBS_PAGE.MUST_HAVE_SKILL")}</th>
              <th>{t("JOBS_PAGE.NICE_TO_HAVE_SKILL")}</th>
              <th>{t("JOBS_PAGE.MATCHED_PROFILE")}</th>
              <th>{t("JOBS_PAGE.LOCATION")}</th>
              <th>{t("JOBS_PAGE.STATUS")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props?.jobs?.map((jobs, key) => (
              <tr key={jobs.id}>
                <td>{serialNumber + key}</td>
                <td>{jobs.Title}</td>
                <td>
                  {jobs.minimum_experience != null
                    ? `${jobs.minimum_experience} - ${jobs.maximum_experience}`
                    : "-"}
                </td>
                <td>
                  {jobs.Must_have_skills.length > 0
                    ? jobs.Must_have_skills.map((l) => l).join(", ")
                    : "-"}
                </td>
                <td>
                  {jobs.Nice_to_have_skills.length > 0
                    ? jobs.Nice_to_have_skills.map((l) => l).join(", ")
                    : "-"}
                </td>
                <td>{jobs.Matched_profiles}</td>
                <td>{jobs.job_location}</td>
                <td>{t("JOBS_PAGE.ACTIVE")}</td>
                <td>
                  <Dropdown data-testid={`dropdown-button-${jobs.id}`}>
                    <Dropdown.Toggle variant="clear">
                      <FaEllipsisH />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        data-testid="checkMatch-job"
                        onClick={() => {
                          void handleCheckMatch(jobs.id);
                        }}
                      >
                        <TiPuzzle />
                        {t("JOBS_PAGE.CHECK_MATCH")}
                      </Dropdown.Item>
                      <Dropdown.Item href="">
                        <MdFileDownload />
                        <span
                          data-testid="download-job"
                          onClick={() => {
                            downloadPopup(jobs.id);
                          }}
                        >
                          {t("JOBS_PAGE.DOWNLOAD_JD")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        data-testid="edit-job"
                        onClick={() => {
                          navigate(editJobPath + "?id=" + jobs.id.toString());
                        }}
                      >
                        <RiEdit2Fill />
                        {t("JOBS_PAGE.EDIT_JD")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        data-testid="delete-job"
                        onClick={(e) => {
                          showDeleteJobPopup(jobs.id);
                        }}
                      >
                        <RiDeleteBin5Fill />
                        {t("JOBS_PAGE.DELETE_JD")}
                      </Dropdown.Item>
                      <Dropdown.Item href="">
                        <AiFillLinkedin />
                        {t("JOBS_PAGE.SHARE_ON_LINKEDIN")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      <ConfirmationModal
        id={id}
        setShowPopup={(close: boolean) => {
          setShowPopup(close);
        }}
        showPopup={showPopup}
        handleButtonClick={() => {
          props.onDelete?.(id);
          setShowPopup(false);
        }}
        modelDescription={t("JOBS_PAGE.CONFIRMATION_MESSAGE")}
        buttonLabel={t("JOBS_PAGE.YES_BTN")}
      />
    </div>
  );
}
