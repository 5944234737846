import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Container,
  FloatingLabel,
  Form,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { jobsPath } from "../../../utils/routes";
import { useTranslation } from "react-i18next";
import Accordian from "../../../widgets/Accordian";
import { AiFillMinusCircle } from "react-icons/ai";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { RiBriefcase4Fill } from "react-icons/ri";
import {
  addJobDetails,
  getAllSubSkills,
  getJobDetailsById,
  type ISkillList,
  updateJdDetailsById,
  type IAllSubSkillsResponse,
  type IJobDescDetailsPageResponse,
  addSkillList,
  IAddSkillRequest,
  IAddSkillResponse,
  IJobDetailsResponse,
} from "../../../services/jobsAPIService";
import { useLocation, useNavigate } from "react-router-dom";
import Toaster, { type IToasterProps } from "../../../widgets/Toaster";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

export interface IJobDetails {
  id?: number;
  Title: string;
  minimum_experience: number | null;
  maximum_experience: number | null;
  No_of_projects_completed: number;
  Domain?: string | null;
  job_description: string;
  Template_Link: number;
  CreatedBy?: string;
  UpdatedBy?: string;
  jd_skill_list: ISkillList[];
  job_location: string;
}

export interface ISkillValues {
  label: string;
  value: string;
  id: number;
}

export default function CreateNewJob(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.search.includes("id");
  const jobId = location.search.split("=")[1];
  const [subSkillSet, setSubSkillSet] = useState<IAllSubSkillsResponse[]>([]);
  const [errorMsg, setErrorMsg] = useState({
    jobTitleError: false,
    yearsExpError: false,
    validYearsExpError: false,
    fromYearshouldGreater: false,
    jobDesError: false,
    locationError: false,
    domainError: false,
    domainValueError: false,
    skillError: false,
    skillvalidError: false,
  });
  const [jobDetails, setJobDetails] = useState<IJobDetails>({
    Title: "",
    minimum_experience: null,
    maximum_experience: null,
    No_of_projects_completed: 0,
    Domain: "",
    job_description: "",
    Template_Link: 1,
    CreatedBy: "Admin",
    id: 0,
    job_location: "",
    jd_skill_list: [
      {
        skill: "",
        Experience_Required_for_skill: 0,
        IsMandatory: true,
        extras: {
          skill: "None",
          id: 0,
        },
        id: 0,
        Jd_id: 0,
      },
    ],
  });
  const [toasters, setToasters] = useState<IToasterProps[]>([]);

  const [defaultIndex, setDefaultIndex] = React.useState(0);
  const [filteredSkillSet, setFilteredSkillSet] = useState<
    IAllSubSkillsResponse[]
  >([]);

  useEffect(() => {
    if (filteredSkillSet.length > 0) {
      (document.querySelector(".active-dropdown") as HTMLLIElement)?.focus();
    }
  }, [filteredSkillSet]);

  const addToast = (toast: IToasterProps) => {
    setToasters((prevToasters) => [...prevToasters, toast]);
  };

  useEffect(() => {
    getAllSubSkills()
      .then((resp: IAllSubSkillsResponse[]) => {
        setSubSkillSet(resp);
      })
      .catch((err: Error) => {
        addToast({
          id: 1,
          message: err.message,
          variant: "danger",
        });
      });
    jobId !== undefined && getJdDetailsById(Number.parseInt(jobId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      if (
        !e.target.closest("#skills") &&
        !e.target.closest(".dropdown-options")
      ) {
        setFilteredSkillSet([]);
      }
    };
    window.addEventListener("click", handleDocumentClick);
    return () => {
      window.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // Get Jd Details By id
  const getJdDetailsById = (id: number): void => {
    getJobDetailsById(id)
      .then((resp: IJobDescDetailsPageResponse) => {
        jobDetails.id = resp.id;
        jobDetails.jd_skill_list = resp.skill_list;
        jobDetails.Title = resp.Title;
        jobDetails.Domain = resp.Domain;
        jobDetails.job_description = resp.job_description;
        jobDetails.UpdatedBy = "Neeraj Patil";
        jobDetails.minimum_experience = resp.minimum_experience;
        jobDetails.maximum_experience = resp.maximum_experience;
        jobDetails.job_location = resp.job_location;
        setJobDetails({ ...jobDetails });
      })
      .catch((err: Error) => {
        addToast({
          id: 2,
          message: err.message,
          variant: "danger",
        });
      });
  };

  // Update Edit Jd
  const updateJobDetailsById = (id: number, data: IJobDetails): void => {
    const jobSkills = jobDetails.jd_skill_list.map((jobSkill) =>
      jobSkill.skill.toLowerCase()
    );
    const subSkills = subSkillSet.map((subSkill) =>
      subSkill.subskills.toLowerCase()
    );
    const nonMatching = jobSkills.filter(
      (jobSkill) => !subSkills.includes(jobSkill)
    );
    if (nonMatching.length > 0) {
      handleAddNewSkill(nonMatching);
    }
    void updateJdDetailsById(data, id).then((resp: string) => {
      if (resp !== null) {
        navigate(jobsPath);
      }
    });
  };

  // Get inputs from Create New Jd

  const handleInputField = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { id, value } = e.target;
    const normalizedValue = value.replace(/\s+/g, " ");
    switch (id) {
      case "title":
        setJobDetails((jobData) => ({
          ...jobData,
          Title: normalizedValue,
        }));
        setErrorMsg((errMsg) => ({ ...errMsg, jobTitleError: false }));
        return;
      case "from":
        if (/^\d{0,2}$/.test(e.target.value)) {
          setJobDetails((jobData) => ({
            ...jobData,
            minimum_experience:
              e.target.value === "" ? null : Number(e.target.value),
          }));
          setErrorMsg((errMsg) => ({
            ...errMsg,
            yearsExpError: false,
            validYearsExpError: false,
          }));
        }
        if (!/^\d/.test(e.target.value)) {
          if (e.target.value !== "") {
            setErrorMsg((errMsg) => ({
              ...errMsg,
              validYearsExpError: true,
              yearsExpError: false,
            }));
          }
          // else if (fromExpElement >= toExpElement) {
          //   setErrorMsg((errMsg) => ({
          //     ...errMsg,
          //     validYearsExpError: false,
          //     yearsExpError: true,
          //   }));
          // }
          else {
            setErrorMsg((errMsg) => ({
              ...errMsg,
              validYearsExpError: false,
              yearsExpError: true,
            }));
          }
        }
        if (
          jobDetails?.maximum_experience !== null &&
          Number(e.target.value) <= jobDetails?.maximum_experience
        ) {
          setErrorMsg((errMsg) => ({
            ...errMsg,
            fromYearshouldGreater: false,
          }));
          return;
        }
        return;
      case "to":
        if (/^\d{0,2}$/.test(e.target.value)) {
          setJobDetails((jobData) => ({
            ...jobData,
            maximum_experience:
              e.target.value === "" ? null : Number(e.target.value),
          }));

          setErrorMsg((errMsg) => ({
            ...errMsg,
            yearsExpError: false,
            validYearsExpError: false,
          }));
        }
        if (!/^\d/.test(e.target.value)) {
          if (e.target.value !== "") {
            setErrorMsg((errMsg) => ({
              ...errMsg,
              validYearsExpError: true,
              yearsExpError: false,
            }));
          } else {
            setErrorMsg((errMsg) => ({
              ...errMsg,
              validYearsExpError: false,
              yearsExpError: true,
            }));
          }
        }
        if (
          jobDetails?.minimum_experience !== null &&
          Number(e.target.value) >= jobDetails?.minimum_experience
        ) {
          setErrorMsg((errMsg) => ({
            ...errMsg,
            fromYearshouldGreater: false,
          }));
          return;
        }
        return;
      case "domain":
        setJobDetails((jobData) => ({
          ...jobData,
          Domain: normalizedValue || null,
        }));
        if (/[0-9]/.test(e.target.value)) {
          setErrorMsg((errMsg) => ({
            ...errMsg,
            domainError: e.target.value === "",
            domainValueError: e.target.value !== "",
          }));
        } else {
          setErrorMsg((errMsg) => ({
            ...errMsg,
            domainError: false,
            domainValueError: false,
          }));
        }
        return;
      case "desc":
        setJobDetails((jobData) => ({
          ...jobData,
          job_description: normalizedValue,
        }));
        setErrorMsg((errMsg) => ({ ...errMsg, jobDesError: false }));
        return;
      case "location":
        setJobDetails((jobData) => ({
          ...jobData,
          job_location: normalizedValue,
        }));
        setErrorMsg((errMsg) => ({ ...errMsg, locationError: false }));
        return;
      case "skills": {
        const updatedSkills = [...jobDetails.jd_skill_list];
        updatedSkills[index].skill = normalizedValue;
        setJobDetails((jobData) => ({
          ...jobData,
          jd_skill_list: updatedSkills,
        }));
      }
    }
  };
  // For Required Skill
  const handleSkillTypeChange = (selector: string, index: number): void => {
    if (selector === "Must have skill") {
      jobDetails.jd_skill_list[index].IsMandatory = true;
    } else if (selector === "Nice to have skill") {
      jobDetails.jd_skill_list[index].IsMandatory = false;
    }
  };

  // Add new Skill
  const handleAddSkill = (index: number): void => {
    setErrorMsg((errMsg) => ({ ...errMsg, skillError: false }));
    setDefaultIndex(index + 1);
    const tempdata: ISkillList[] = jobDetails.jd_skill_list;
    tempdata.push({
      skill: "",
      Experience_Required_for_skill: 0,
      IsMandatory: true,
      extras: {
        skill: "None",
        id: 0,
      },
    });

    setJobDetails({ ...jobDetails, jd_skill_list: tempdata });
  };

  // Delete Skill
  const handleDeleteSkill = (index: number): void => {
    const tempdata: ISkillList[] = [...jobDetails.jd_skill_list];
    tempdata.splice(index, 1);
    jobDetails.jd_skill_list = tempdata;
    setJobDetails({ ...jobDetails });
    setDefaultIndex(jobDetails.jd_skill_list.length - 1);
  };
  const areSkillsUnique = () => {
    const skillsSet = new Set();

    for (const skillObj of jobDetails.jd_skill_list) {
      const skill = skillObj.skill.toLowerCase();

      if (skillsSet.has(skill)) {
        return false;
      }

      skillsSet.add(skill);
    }

    return true;
  };

  // const fromExpElement = document.getElementById(
  //   "from"
  // ) as HTMLInputElement | null;
  // const toExpElement = document.getElementById("to") as HTMLInputElement | null;

  const validateFields = (index: number, type: string): void => {
    console.log(
      "jobDetails.jd_skill_list[index].skill.trim().length",
      jobDetails.jd_skill_list[index].skill.trim().length
    );
    if (jobDetails.Title.trim().length === 0) {
      setErrorMsg((errMsg) => ({ ...errMsg, jobTitleError: true }));
      return;
    } else if (jobDetails.minimum_experience === null) {
      setErrorMsg((errMsg) => ({ ...errMsg, yearsExpError: true }));
      return;
    } else if (jobDetails.maximum_experience === null) {
      setErrorMsg((errMsg) => ({ ...errMsg, yearsExpError: true }));
      return;
    } else if (
      jobDetails?.minimum_experience >= jobDetails?.maximum_experience
    ) {
      setErrorMsg((errMsg) => ({ ...errMsg, fromYearshouldGreater: true }));
      return;
    } else if (jobDetails.jd_skill_list[index].skill.trim().length === 0) {
      setErrorMsg((errMsg) => ({ ...errMsg, skillError: true }));
      return;
    } else if (
      !jobDetails.jd_skill_list[0].IsMandatory &&
      jobDetails.jd_skill_list[index].skill.trim().length === 0 &&
      jobDetails.jd_skill_list[index].IsMandatory
    ) {
      addToast({
        id: 3,
        message: t("JOBS_PAGE.API_ERROR_MESSAGE"),
        variant: "danger",
      });
      return;
    } else if (errorMsg.skillvalidError) {
      addToast({
        id: 8,
        message: t("JOBS_PAGE.ADDING_SKILL_WITHOUT_COMMA_IS_INVALID"),
        variant: "danger",
      });
      return;
    } else if (!areSkillsUnique()) {
      addToast({
        id: 4,
        message: t(
          "JOBS_PAGE.DUPLICATE_SKILLS_FOUND_EACH_SKILL_MUST_BE_UNIQUE"
        ),
        variant: "danger",
      });
      return;
    }
    if (type === "next") {
      handleAddSkill(index);
    } else if (isEdit && jobId !== null) {
      updateJobDetailsById(Number.parseInt(jobId), jobDetails);
    } else {
      void createNewJob(jobDetails, index);
    }
  };

  const createNewJob = async (
    data: IJobDetails,
    index: number
  ): Promise<void> => {
    const jobSkills = jobDetails.jd_skill_list.map((jobSkill) =>
      jobSkill.skill.toLowerCase()
    );
    const subSkills = subSkillSet.map((subSkill) =>
      subSkill.subskills.toLowerCase()
    );
    const nonMatching = jobSkills.filter(
      (jobSkill) => !subSkills.includes(jobSkill)
    );
    if (nonMatching.length > 0) {
      handleAddNewSkill(nonMatching);
    }
    const tempdata: ISkillList[] = data.jd_skill_list;
    const result = tempdata.filter((skill) => skill.IsMandatory === true);
    if (data.Domain === "") {
      setJobDetails({ ...jobDetails, Domain: "test" });
    }
    if (result.length > 0) {
      void addJobDetails(data).then((resp: IJobDetailsResponse) => {
        if (resp) {
          navigate(jobsPath);
        }
      });
    } else {
      addToast({
        id: 6,
        message: t("JOBS_PAGE.AT_LEAST_ONE_MANDATORY_SKILL_IS_REQUIRED"),
        variant: "danger",
      });
    }
  };

  const handleAddNewSkill = (skillValue: string[]) => {
    let newSkillValues = skillValue.map((skillData: string) => ({
      skill: skillData,
      subskill: [skillData],
    }));
    let addSkillData: IAddSkillRequest = {
      data: newSkillValues,
    };
    addSkillList(addSkillData)
      .then((resp: IAddSkillResponse) => {
        sessionStorage.setItem("jobid", resp.job_status_id.toString());
      })
      .catch((err: Error) => {
        addToast({
          id: 7,
          message: err.message,
          variant: "danger",
        });
      });
  };

  const handleOnchangeOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    let value = e.target.value;

    const trimmedValue = value.trim();
    const normalizedValue = trimmedValue.replace(/\s+/g, " ");

    if (normalizedValue.length !== 0) {
      handleFilterSkillSet(normalizedValue, index);
      setErrorMsg((errMsg) => ({ ...errMsg, skillError: false }));

      if (/[;,]/.test(normalizedValue.toLowerCase())) {
        setErrorMsg((errMsg) => ({ ...errMsg, skillvalidError: true }));
      } else {
        setErrorMsg((errMsg) => ({ ...errMsg, skillvalidError: false }));
      }
    } else {
      setFilteredSkillSet([]);
    }

    const updatedSkills = [...jobDetails.jd_skill_list];
    updatedSkills[index].skill = normalizedValue;
    setJobDetails({ ...jobDetails, jd_skill_list: updatedSkills });
  };

  const handleFilterSkillSet = (value: string, index: number) => {
    const existingSkills = jobDetails.jd_skill_list.map((skill) =>
      skill.skill.toLowerCase()
    );

    const filteredSkills = subSkillSet.filter(
      (skill) =>
        skill.subskills.toLowerCase().includes(value.toLowerCase()) &&
        !existingSkills.includes(skill.subskills.toLowerCase())
    );

    setFilteredSkillSet(filteredSkills);
  };

  return (
    <>
      {toasters.map((toast) => (
        <Toaster key={toast.id} {...toast} />
      ))}
      <div className="container-fluid createNewJob modal-open">
        <Container>
          <Row className="mx-3 my-2">
            <Breadcrumb data-testid="breadcrum-text">
              <Breadcrumb.Item
                onClick={() => {
                  navigate(jobsPath);
                }}
              >
                {t("JOBS_PAGE.JOBS")}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {isEdit ? (
                  <strong>{t("JOBS_PAGE.EDIT_JOB")}</strong>
                ) : (
                  <strong>{t("JOBS_PAGE.NEW_JOB")}</strong>
                )}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <Container className="px-4 ">
          <Accordian
            heading={
              <div className="fw-bolder accHead py-2">
                <RiBriefcase4Fill className="mx-2" />
                {t("JOBS_PAGE.ACCORDIAN_HEADER")}
              </div>
            }
            eventKey=""
          >
            <Container
              className="d-flex justify-content-center my-3"
              data-testid="fields-container"
            >
              <Col xl={10} lg={10} md={12} xs={12}>
                <Row>
                  <Col xl={12} lg={12} md={12} xs={12}>
                    <FloatingLabel
                      label={
                        <>
                          {t("JOBS_PAGE.NEW_JOB_TITLE")}
                          <span className="createNewJob__color-red"> *</span>
                        </>
                      }
                      className="mt-4 mb-2"
                    >
                      <Form.Control
                        type="text"
                        id="title"
                        required
                        value={jobDetails.Title}
                        placeholder={t("JOBS_PAGE.NEW_JOB_TITLE")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputField(e, 0);
                        }}
                      />
                    </FloatingLabel>
                    {errorMsg?.jobTitleError && (
                      <span className="createNewJob-error-text">
                        {t("JOBS_PAGE.TITLE_ERROR")}
                      </span>
                    )}
                  </Col>
                </Row>

                <Row className="align-items-center">
                  <Col xl={6} lg={6} md={12} xs={12}>
                    <div className="d-flex my-3 align-items-center">
                      <div className="me-0 me-md-4 d-flex">
                        <div>{t("JOBS_PAGE.TOTAL_YRS_OF_EXP_PLACEHOLDER")}</div>
                        <div className="createNewJob__color-red ms-2"> *</div>
                      </div>
                      <Form.Control
                        type="text"
                        className="me-0 me-md-3"
                        id="from"
                        value={
                          jobDetails.minimum_experience !== null
                            ? jobDetails.minimum_experience
                            : ""
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputField(e, 0);
                        }}
                      />
                      <div className="mx-2"> To </div>
                      <Form.Control
                        type="text"
                        className="ms-3"
                        id="to"
                        value={
                          jobDetails.maximum_experience !== null
                            ? jobDetails.maximum_experience
                            : ""
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputField(e, 1);
                        }}
                      />
                    </div>

                    {errorMsg?.yearsExpError && (
                      <span className="createNewJob-error-text">
                        {t("JOBS_PAGE.EXP_ERROR")}
                      </span>
                    )}
                    {errorMsg?.validYearsExpError && (
                      <span className="createNewJob-error-text">
                        {t("JOBS_PAGE.VALID_YEARS_EXPERIENCE")}
                      </span>
                    )}
                    {errorMsg?.fromYearshouldGreater && (
                      <span className="createNewJob-error-text">
                        {t("JOBS_PAGE.RANGE_OF_EXPERIENCE_IS_INVALID")}
                      </span>
                    )}
                  </Col>
                  <Col xl={6} lg={6} md={12} xs={12}>
                    <FloatingLabel
                      label={t("JOBS_PAGE.DOMAIN")}
                      className="my-3"
                    >
                      <Form.Control
                        type="text"
                        id="domain"
                        value={jobDetails.Domain ?? ""}
                        placeholder={t("JOBS_PAGE.DOMAIN")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputField(e, 0);
                        }}
                      />
                    </FloatingLabel>

                    {errorMsg?.domainValueError && (
                      <span className="createNewJob-error-text">
                        {t("JOBS_PAGE.ENTER_A_VALID_DOMAIN")}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} lg={6} md={6} xs={12}>
                    <FloatingLabel
                      label={t("JOBS_PAGE.JOB_DESC")}
                      className="my-2"
                    >
                      <Form.Control
                        as="textarea"
                        id="desc"
                        value={jobDetails.job_description}
                        placeholder={t("JOBS_PAGE.JOB_DESC")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputField(e, 0);
                        }}
                      />
                    </FloatingLabel>
                    {errorMsg?.jobDesError && (
                      <span className="createNewJob-error-text">
                        {t("JOBS_PAGE.DESC_ERROR")}
                      </span>
                    )}
                  </Col>
                  <Col xl={6} lg={6} md={6} xs={12}>
                    <FloatingLabel
                      label={t("JOBS_PAGE.JOB_LOCATION")}
                      className="my-2"
                    >
                      <Form.Control
                        id="location"
                        value={jobDetails.job_location}
                        placeholder={t("JOBS_PAGE.JOB_LOCATION")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputField(e, 0);
                        }}
                      />
                    </FloatingLabel>
                    {errorMsg?.locationError && (
                      <span className="createNewJob-error-text">
                        {t("JOBS_PAGE.LOCATION_ERROR")}
                      </span>
                    )}
                  </Col>
                </Row>

                {jobDetails?.jd_skill_list?.map((jobdata, index) => (
                  <Row key={index}>
                    <Col xl={6} lg={6} md={6} xs={12} className=" my-3">
                      <FloatingLabel label="">
                        <Autocomplete
                          freeSolo
                          options={filteredSkillSet.map(
                            (skill) => skill.subskills
                          )}
                          onSelect={(event: any) => {
                            handleOnchangeOption(event, index);
                          }}
                          value={jobdata.skill}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <div className="text-black">
                                  {t("JOBS_PAGE.SKILLS")}
                                  <span className="createNewJob__color-red">
                                    {" "}
                                    *
                                  </span>
                                </div>
                              }
                            />
                          )}
                        />
                      </FloatingLabel>
                      {errorMsg?.skillError &&
                        index + 1 === jobDetails.jd_skill_list.length && (
                          <span className="createNewJob-error-text">
                            {t("JOBS_PAGE.SKILLS_ERROR")}
                          </span>
                        )}
                      {errorMsg?.skillvalidError &&
                        index + 1 === jobDetails.jd_skill_list.length && (
                          <span className="createNewJob-error-text">
                            {t("JOBS_PAGE.SKILLS_VALIDATION_ERROR")}
                          </span>
                        )}
                    </Col>
                    <Col xl={6} lg={6} md={6} xs={12} className="my-3">
                      <div className="container-fluid p-0 d-flex justify-content-between">
                        <Col xl={12} lg={8} md={8} xs={12}>
                          <FloatingLabel label={t("JOBS_PAGE.SELECT_SKILLS")}>
                            <Form.Select
                              onChange={(e) => {
                                handleSkillTypeChange(e.target.value, index);
                              }}
                            >
                              <option
                                selected={jobdata.IsMandatory}
                                value="Must have skill"
                              >
                                {t("JOBS_PAGE.MUST_HAVE_SKILL")}
                              </option>
                              <option
                                selected={!jobdata.IsMandatory}
                                value="Nice to have skill"
                              >
                                {t("JOBS_PAGE.NICE_TO_HAVE_SKILL")}
                              </option>
                            </Form.Select>
                          </FloatingLabel>
                        </Col>
                        {index + 1 === jobDetails.jd_skill_list.length && (
                          <Col
                            xl={1}
                            lg={4}
                            md={1}
                            xs={1}
                            className="align-self-center mx-1"
                          >
                            <BsFillPlusCircleFill
                              id="add"
                              data-testid="add-new-skill-btn"
                              className="createNewJob__plus-sign-button"
                              onClick={() => {
                                validateFields(index, "next");
                              }}
                            />
                          </Col>
                        )}
                        <Col
                          xl={3}
                          lg={4}
                          md={1}
                          xs={1}
                          className="align-self-center mx-1"
                          data-testid="delete-skill-btn-container"
                        >
                          {jobDetails.jd_skill_list.length > 1 && (
                            <AiFillMinusCircle
                              className="createNewJob__minus-sign-button"
                              onClick={() => {
                                handleDeleteSkill(index);
                              }}
                            />
                          )}
                        </Col>
                      </div>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Container>
          </Accordian>
        </Container>
        <Container className="justify-content-end d-flex px-4 py-4 ">
          <Col xl={1} lg={2} md={3} xs={4} className="p-0 mx-3 ">
            <Button
              onClick={() => {
                validateFields(defaultIndex, "button");
              }}
              data-testid="save-button"
              className="createNewJob__saveBtn"
            >
              {isEdit ? t("JOBS_PAGE.UPDATE") : t("JOBS_PAGE.SAVE")}
            </Button>
          </Col>
          <Col xl={1} lg={2} md={3} xs={4} className="p-0  ">
            <Button
              data-testid="cancel-btn"
              className="createNewJob__CancelBtn"
              onClick={() => {
                navigate(jobsPath);
              }}
            >
              {t("JOBS_PAGE.CANCEL")}
            </Button>
          </Col>
        </Container>
      </div>
    </>
  );
}
