/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  type AxiosResponse,
  type AxiosRequestConfig,
  type AxiosBasicCredentials,
  type ResponseType,
  type AxiosHeaders,
} from 'axios'

export interface IRequestOptions {
  headers?: any
  basicAuth?: AxiosBasicCredentials
  responseType?: ResponseType
}

// const baseUrl ="http://49.206.245.146:8009"
// const baseUrl ="/api/jobs"

// export const onLogout = (): void => {
//   localStorage.removeItem('token')
//   localStorage.removeItem('userName')
//   window.location.href = '/login'
// }

const onRequest = (
  config: AxiosRequestConfig<unknown>,
): AxiosRequestConfig<unknown> => {
  if (Boolean(config.url) ?? false) {
    if (localStorage.getItem('token') !== null) {
      const data = localStorage.getItem('token')
      config.headers.Authorization = 'Bearer ' + (data as string)
    }
  }
  return config
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onResponseSuccess = (
  response: any,
): AxiosResponse<any, any> | Promise<AxiosResponse<any, any>> => {
  endRequest()
  return response
}

const onResponseError = async (err: unknown): Promise<never> => {
  endRequest()
  return await Promise.reject(err)
}

export const axiosInstance = axios.create({
  // baseURL: baseUrl,
  timeout: 1000 * 60 * 5,
  validateStatus: function (status) {
    return status === 200 || status === 201 || status === 204
  },
})

axiosInstance.interceptors.request.use(onRequest)
axiosInstance.interceptors.response.use(onResponseSuccess, onResponseError)
let onRequestStart: () => void
let onRequestEnd: () => void
let totalRequests = 0
let completedRequests = 0
let isShowingLoader = false

const startRequest = (displayLoader: boolean): void => {
  if (displayLoader && !isShowingLoader) {
    onRequestStart?.()
    isShowingLoader = true
  }
  totalRequests += 1
}

const endRequest = (): void => {
  completedRequests += 1
  if (completedRequests === totalRequests) {
    onRequestEnd?.()
    isShowingLoader = false
  }
}

export function addRequestStartListener(callback: () => void): void {
  onRequestStart = callback
}
export function addRequestEndListener(callback: () => void): void {
  onRequestEnd = callback
}

export async function Get<T, D>(
  endPoint: string,
  params?: D,
  requestOptions: IRequestOptions = {},
  displayLoader = true,
): Promise<T> {
  startRequest(displayLoader)
  const res = await axiosInstance.get<T, AxiosResponse<T>, D>(endPoint, {
    params,
    headers: requestOptions.headers,
    responseType: requestOptions.responseType,
  })
  return res.data
}

export async function Post<T, D>(
  endPoint: string,
  data?: D,
  requestOptions: IRequestOptions = {},
  displayLoader = true,
): Promise<AxiosResponse<T>> {
  startRequest(displayLoader)
  const res = await axiosInstance.post<T, AxiosResponse<T>, D>(endPoint, data, {
    headers: requestOptions.headers !== null ? requestOptions.headers : {},
    auth: requestOptions.basicAuth,
  })
  return res
}

export async function Put<T, D>(
  endPoint: string,
  data: D,
  requestOptions: IRequestOptions = {},
  displayLoader = true,
): Promise<T> {
  startRequest(displayLoader)
  const res = await axiosInstance.put<T, AxiosResponse<T>, D>(endPoint, data, {
    headers: requestOptions.headers,
  })
  return res.data
}

export async function Delete<T>(
  endPoint: string,
  requestOptions: IRequestOptions = {},
  displayLoader = true,
): Promise<T> {
  startRequest(displayLoader)
  const res = await axiosInstance.delete<T>(endPoint, {
    headers: requestOptions.headers,
  })
  return res.data
}
